import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const fmsAdminUrl = URL_UTILITY.getFmsAdminUrl;

const getFmsTimesheetList = (context, payload) => {
  const url = fmsAdminUrl + '/time-sheet';
  return http.getApi(url, payload);
};
const addEditTimesheetDetails = (context, payload) => {
  const url = fmsAdminUrl + '/time-sheet';
  return http.putApi(url, payload);
};
const downloadTimesheetList = (context, payload) => {
  const url = fmsAdminUrl + '/time-sheet-download';
  return http.getApi(url, payload);
};
const excelUploadData = (context, excelImportId) => {
  const url = fmsAdminUrl + '/time-sheet/' + excelImportId + '/excel-upload';
  return http.postApi(url);
};
const deleteFmsTimesheet = (context, payload) => {
  const url = fmsAdminUrl + '/time-sheet-delete';
  return http.postApi(url, payload);
};
const getFmsTimesheetEmployeeData = (context, payload) => {
  const url = fmsAdminUrl + '/edit-timesheet details';
  return http.getApi(url, payload);
};
const addEditEmployeeTimesheetDetails = (context, payload) => {
  const url = fmsAdminUrl + '/edit-time-sheet';
  return http.putApi(url, payload);
};
const checkFmsTimesheet = (context, payload) => {
  const url = fmsAdminUrl + '/existing-timesheet';
  return http.getApi(url, payload);
};
const getFmsUserDetail = (context, payload) => {
  const url = fmsAdminUrl + '/fms_userwise_timesheet_uax';
  return http.getApi(url, payload);
};
const getFmsUserDetailById = (context, userId) => {
  const url = fmsAdminUrl + '/fms_userwise_timesheet_uax/'+ userId;
  return http.getApi(url);
};
const addEditFmsUserDetails = (context, payload) => {
  const url = fmsAdminUrl + '/fms_userwise_timesheet/' + payload.user_id;
  return http.postApi(url, payload.requestParams);
};
const getFmsTimesheetSummaryList = (context, payload) => {
  const url = fmsAdminUrl + '/fms-timesheet-summary';
  return http.getApi(url, payload);
};
const getFmsTimesheetSummaryHdrById = (context, payload) => {
  const url = fmsAdminUrl + '/fms-timesheet-summary-by-hdr-id-dtls/' + payload;
  return http.getApi(url);
};
const getFmsTimesheetSummaryDetailsById = (context, payload) => {
  const url = fmsAdminUrl + '/fms-timesheet-summary-by-id';
  return http.getApi(url, payload);
};
const editTimesheetSummaryDetails = (context, payload) => {
  const url = fmsAdminUrl + '/add-edit-time-sheet';
  return http.postApi(url, payload);
};
const fmsTimesheetProcess = (context, payload) => {
  const url = fmsAdminUrl + '/verify-timesheet-summary/'+ payload.timesheet_summary_hdr_id;
  return http.postApi(url);
};
const fmsTimesheetUnprocess = (context, payload) => {
  const url = fmsAdminUrl + '/unprocess-timesheet-summary-by-hdr_id';
  return http.postApi(url, payload);
};
const deleteTimesheetSummaryDetailByLineId = (context, payload) => {
  const url = fmsAdminUrl + '/delete-timesheet-summary-by-line-id';
  return http.postApi(url, payload);
};
const deleteFmsTimesheetSummaryByHdrId = (context, payload) => {
  const url = fmsAdminUrl +'/delete-timesheet-summary-by-hdr_id';
  return http.postApi(url, payload);
};
const downloadFmsTimesheetSummaryDetailsById = (context, payload) => {
  const url = fmsAdminUrl +'/fms-timesheet-summary-by-id-excel-download';
  return http.getApi(url, payload);
};
const timsheetSummaryexcelUploadData = (context, excelImportId) => {
  const url = fmsAdminUrl + '/timesheet-summary-excelupload/' + excelImportId + '/excel-upload';
  return http.postApi(url);
};
const editTimesheetSummaryRemarks = (context, payload) => {
  const url = fmsAdminUrl + '/timesheet-summary-hdr-remarks-edit';
  return http.postApi(url, payload );
};
const getTimesheetSummaryDetailsById = (context, payload) => {
  const url = fmsAdminUrl + '/bills/timesheet-summary/details';
  return http.getApi(url, payload);
};
const getSummaryData = (context, payload) => {
  const url = fmsAdminUrl + '/fms-timesheet-summary-hdr-id-summary/'+ payload.timesheet_summary_hdr_id;
  return http.getApi(url);
};
const getBillingDashboardData = (context, payload) => {
  const url = fmsAdminUrl + '/user-dashboard';
  return http.getApi(url, payload);
};
const getForMeDashboardData = (context, payload) => {
  const url = fmsAdminUrl + '/user-dashboard-get-days';
  return http.getApi(url, payload);
};
const getdashboardGlobalData = (context, payload) => {
  const url = fmsAdminUrl + '/user-dashboard-global';
  return http.getApi(url, payload);
};
export default {
  getFmsTimesheetList,
  addEditTimesheetDetails,
  downloadTimesheetList,
  excelUploadData,
  deleteFmsTimesheet,
  getFmsTimesheetEmployeeData,
  addEditEmployeeTimesheetDetails,
  checkFmsTimesheet,
  getFmsUserDetail,
  getFmsUserDetailById,
  addEditFmsUserDetails,
  getFmsTimesheetSummaryList,
  getFmsTimesheetSummaryHdrById,
  getFmsTimesheetSummaryDetailsById,
  editTimesheetSummaryDetails,
  fmsTimesheetProcess,
  fmsTimesheetUnprocess,
  deleteTimesheetSummaryDetailByLineId,
  deleteFmsTimesheetSummaryByHdrId,
  downloadFmsTimesheetSummaryDetailsById,
  timsheetSummaryexcelUploadData,
  editTimesheetSummaryRemarks,
  getTimesheetSummaryDetailsById,
  getSummaryData,
  getBillingDashboardData,
  getForMeDashboardData,
  getdashboardGlobalData
};
