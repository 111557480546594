import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import city from './admin/city';
import country from './admin/country';
import currency from './admin/currency';
import receipt from './admin/receipt';
import organization from './admin/organization';
import fms from './fms/receipt';
import party from './admin/party';
import shared from './shared';
import defineUser from './admin/defineUser';
import resp from './admin/resp';
import docSetup from './admin/docSetup';
import flexfield from './admin/flexfield';
import taxCat from './tax/taxCat';
import taxRule from './tax/taxRule';
import bankMpngMode from './fms/bankMpngMode';
import role from './admin/role';
import requestGroup from './request/requestGroup';
import requestMaster from './request/requestMaster';
import template from './request/template';
import taxGroup from './tax/taxgroup';
import defineTaxMaster from './tax/defineTaxMaster';
import defineTaxSection from './tax/defineTaxSection';
import defineTaxType from './tax/defineTaxType';
import defineTaxRegime from './tax/defineTaxRegime';
import valueset from './admin/valueset';
import hrms from './admin/hrms';
import workflow from './admin/workflow';
import unitInventory from './fms/unitInventory';
import intWorkflow from './glUser/internalWorkflow';
import oracleApi from './oracleApi';
import defineProfileOptions from './admin/defineProfileOptions';
import projects from './projects';
import crmInventoryProperty from './crmInventoryProperty';
import calendar from './admin/calendar';
import sob from './admin/sob';
import openClose from './admin/openClose';
import lookup from './admin/lookup';
import menu from './admin/menu';
import masterData from './fms/masterData';
import menuMapping from './admin/menuMapping';
import inventoryLocationMapping from './admin/inventoryLocationMapping';
import leaseProject from './lease/leaseProject';
import twoSixASUpload from './tds';
import fmsTransaction from './fms/transaction';
import workdayCalendar from './fms/workdayCalendar';
import fmsTimesheet from './fms/fmsTimesheet';
import leaseTower from './lease/leaseTower';
import leaseFloor from './lease/leaseFloor';
import leaseUnit from './lease/leaseUnit';
import secGroup from './admin/secGrp';
import gstCredentials from './gst/masterGst/gstCredentials';
import gstMaster from './gst/masterGst/gstMaster';
import gstrTwoADownload from './gst/gstrTwoA/gstrTwoADownload';
import leaseAssignSubUnit from './lease/leaseAssignSubunit';
import leases from './lease/leases';
import itcSummaryReport from './gst/gstrTwoA/itcSummaryReport';
import leaseSchedule from './lease/leaseSchedule';
import leaseGenerateBatch from './lease/leaseGenerateBatch';
import leaseInvoice from './lease/leaseInvoice';
import receivables from './receivable';
import subModule from './admin/subModule';
import codeCombination from './admin/codeCombination';
import taxRegimeRegistration from './tax/taxRegimeRegistration';
import taxRegimeAccount from './tax/taxRegimeAccount';
import payable from './payable';
import leaseRecovery from './lease/leaseRecovery';
import applyReceipt from './arReceivables/applyReceipt';
import manualPayments from './payable/manualPayments';
import realManageAdmin from './realManageAdmin';
import hostToHostBankTransfer from './payable/hostToHostBankTransfer';
import adviceStatus from './payable/adviseStatus';
import apiAdmin from './apiAdmin';
import invAdmin from './invAdmin';
import portalRealApp from './portalRealApp';
import itcDataUpdation from './gst/gstrTwoA/itcDataUpdation';
import assets from './assets';
import itcReconciliation from './gst/gstrTwoA/itcReconciliation';
import dunningAdmin from './dunning';
import rgpManagement from './rgpManagement';
import gstrThreeBSummary from './gst/gstrTwoA/gstrThreeBSummary';
import complainceDashboard from './gst/gstrTwoA/complainceDashboard';
import salesAgreement from './fms/salesAgreement';
import leaseInvestorPayment from './lease/leaseInvestorPayment';
import customerBalances from './cms/customerBalances';
import collectionDashboard from './cms/collectionDashboard';
import arInvoicePrint from './fusion/arInvoicePrint';
import sqlConnect from "./fusion/sqlConnect";
import source from './source';
import emailler from './gst/gstrTwoA/emailler';
import rfp from "./rfp";
import codReco from './cod/codReco';
import provisionsAdmin from './provisionsAdmin';
Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    status: '',
    token: sessionStorage.getItem('token'),
    user: {},
    isLoading: false,
    error: false,
    message: ''
  },
  modules: {
    auth,
    country,
    city,
    receipt,
    currency,
    fms,
    party,
    shared,
    defineUser,
    organization,
    resp,
    docSetup,
    flexfield,
    role,
    requestGroup,
    requestMaster,
    template,
    taxCat,
    taxRule,
    taxGroup,
    defineTaxMaster,
    defineTaxSection,
    defineTaxType,
    bankMpngMode,
    defineTaxRegime,
    valueset,
    hrms,
    workflow,
    unitInventory,
    intWorkflow,
    oracleApi,
    defineProfileOptions,
    projects,
    crmInventoryProperty,
    calendar,
    sob,
    openClose,
    lookup,
    menu,
    masterData,
    menuMapping,
    inventoryLocationMapping,
    leaseProject,
    twoSixASUpload,
    fmsTransaction,
    leaseTower,
    leaseFloor,
    leaseUnit,
    secGroup,
    gstCredentials,
    gstMaster,
    gstrTwoADownload,
    leaseAssignSubUnit,
    leases,
    itcSummaryReport,
    leaseSchedule,
    leaseGenerateBatch,
    leaseInvoice,
    receivables,
    subModule,
    codeCombination,
    taxRegimeRegistration,
    taxRegimeAccount,
    payable,
    leaseRecovery,
    applyReceipt,
    manualPayments,
    realManageAdmin,
    hostToHostBankTransfer,
    adviceStatus,
    apiAdmin,
    invAdmin,
    portalRealApp,
    itcDataUpdation,
    assets,
    itcReconciliation,
    dunningAdmin,
    rgpManagement,
    gstrThreeBSummary,
    complainceDashboard,
    salesAgreement,
    workdayCalendar,
    fmsTimesheet,
    leaseInvestorPayment,
    customerBalances,
    collectionDashboard,
    arInvoicePrint,
    sqlConnect,
    source,
    emailler,
    rfp,
    codReco,
    provisionsAdmin,
  }
});
