<template>
  <div>
    <div class="overlay" v-if="loader">
      <b-spinner class="loader" variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
      <b-img
        @click="homeIconClikced()"
        v-bind="logoProps"
        :src="require('@/assets/imgs/revent_logo.png')"
        fluid
        alt="revent image"
        style="cursor: pointer"
      ></b-img>
      <b-navbar-brand href="#" v-if="activeMenu">
        <button class="btn btn-vatika text-start">
          {{ activeMenu }}
        </button>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto flex nav-right">
          <a v-if="showUserManualLink" target="_blank" class="">
            <b-button
              pill
              variant="info"
              style="margin-right: 20px; margin-top: 10px; padding: 0.3rem 1rem !important;font-weight:600"
              title="Download User Manual"
              :href="downloadGstUserManualUrl + '/' + userManualGstFile"
              >User Manual</b-button
            >
          </a>
          <span class="user-name" v-if="userName">{{
            userName && `Welcome - ${userName}`
          }}</span>

          <!-- <b-nav-item-dropdown right v-if="leList"> -->
          <!-- Using 'button-content' slot -->
          <!-- <template #button-content>
              <b class="btn btn-vatika text-start">{{
                leList[0].org_short_name
              }}</b>
            </template>
            <b-dropdown-item
              href="#"
              v-for="(item, index) in leList"
              :key="index"
              >{{ item.org_short_name }}</b-dropdown-item
            >
          </b-nav-item-dropdown> -->

          <b-row>
            <b-col md="12">
              <b-row class="text-center" style="vertical-align: middle">
                <b-col
                  class="btn-icons"
                  md="7"
                  style="padding-top: 15px"
                  v-if="userName"
                >
                  <b-row>
                    <b-col class="p-1" style="cursor: pointer">
                      <div>
                        <b-icon
                          icon="lock-fill"
                          aria-hidden="true"
                          :title="'Change Password'"
                          @click="showChangePasswordForm"
                        ></b-icon>
                      </div>
                    </b-col>
                    <b-col class="p-1" style="cursor: pointer">
                      <div>
                        <b-icon
                          icon="bell"
                          aria-hidden="true"
                          @click="showNotificationSummary"
                          :title="'Notifications'"
                        ></b-icon>
                        <span
                          class="noti-counter"
                          @click="showNotificationSummary"
                          >{{ notificationCount }}</span
                        >
                      </div>
                    </b-col>
                    <b-col class="p-1" style="cursor: pointer">
                      <div>
                        <b-icon
                          icon="envelope"
                          aria-hidden="true"
                          :title="'Mail'"
                        ></b-icon>
                      </div>
                    </b-col>
                    <b-col class="p-1" style="cursor: pointer">
                      <div>
                        <font-awesome-icon
                          :title="'Log Out'"
                          icon="sign-out-alt"
                          @click="singout"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col style="align-self: center" class="p-0 login-section">
                  <div>
                    <b-img
                      :src="require('@/assets/imgs/DrivenbyValues.png')"
                      fluid
                      alt="Vatika image"
                      class="pl-0"
                      style="height: 25px"
                    ></b-img>
                  </div>
                    <div style="display: flex; justify-content: flex-end">
                      <div v-if="!isAuthenticated">
                        <a
                          title=""
                          class="LoginBtn"
                          @click="showHideLoginFrom()"
                          >Login</a
                        >
                      </div>
                    </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class="flex" v-if="showLoginForm">
              <form class="login" @submit.prevent="login">
                <div class="login-form popoverForm">
                  <h1>{{ title }}</h1>

                  <div class="form-group">
                    <div
                      v-if="error.message"
                      class="alert alert-danger"
                      role="alert"
                    >
                      {{ error.message }}
                    </div>
                    <div
                      class="alert alert-success"
                      v-if="status === 'error'"
                      role="alert"
                    >
                      {{ message }}
                    </div>
                    <div class="" v-if="status === 'verify'">
                      <div class="alert alert-success">
                        OTP was sent to your email address:
                      </div>
                    </div>

                    <div v-if="reset" class="alert alert-success">
                      Request completed successfully
                    </div>

                    <div
                      v-if="
                        status != 'setPassword' ||
                          (status === 'success' && status != 'success')
                      "
                    >
                      <label for="username">Username</label>
                      <input
                        type="text"
                        class="form-control"
                        id="username"
                        placeholder="Enter your username"
                        v-model="email"
                        v-on:blur="validateEmail"
                        v-on:focus="
                          error = {
                            message: ''
                          };
                          status = 'login';
                        "
                        :disabled="status != 'login'"
                      />
                    </div>

                    <div
                      v-if="error.reset"
                      class="mt-2 alert alert-danger"
                      role="alert"
                    >
                      {{ error.reset }}
                    </div>
                  </div>

                  <div
                    class="form-group"
                    v-if="status === 'login' || status === 'success'"
                  >
                    <label for="password">Password</label>
                    <div
                      style="display: flex; flex-direction: row"
                      class="mt-1"
                    >
                      <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        input-disabled="true"
                        input-type="password"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                      />
                    </div>
                  </div>

                  <div class="form-group" v-if="status === 'verify'">
                    <label for="password">OTP</label>
                    <div
                      style="display: flex; flex-direction: row"
                      class="mt-1"
                    >
                      <v-otp-input
                        ref="verifyOtp"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        input-disabled="true"
                        input-type="password"
                        @on-change="handleOnVerifyOTP"
                        @on-complete="handleOnCompleteOTP"
                      />
                    </div>

                    <div class="alert alert-danger" v-if="error.verify">
                      <p>OTP is incorrect</p>
                    </div>
                  </div>

                  <div v-if="status === 'setPassword'">
                    <div class="form-group">
                      <div v-if="error.newPassword">
                        <div class="alert alert-danger">
                          {{ error.newPassword }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="password">New Password</label>
                      <div
                        style="display: flex; flex-direction: row"
                        class="mt-1"
                      >
                        <v-otp-input
                          ref="password"
                          input-classes="otp-input"
                          separator="-"
                          :num-inputs="6"
                          :should-auto-focus="true"
                          :is-input-num="true"
                          input-disabled="true"
                          input-type="password"
                          @on-change="handleOnChange"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="password">Verify Password</label>
                      <div
                        style="display: flex; flex-direction: row"
                        class="mt-1"
                      >
                        <v-otp-input
                          ref="verifyPassword"
                          input-classes="otp-input"
                          separator="-"
                          :num-inputs="6"
                          :is-input-num="true"
                          input-disabled="true"
                          input-type="password"
                          @on-complete="handleOnCompleteSetPassword"
                        />
                      </div>
                    </div>
                  </div>
                  <p
                    v-if="
                      status != 'verify' &&
                        status != 'success' &&
                        status != 'setPassword'
                    "
                    @click="resetPassword"
                    :class="isValid ? 'btn' : 'btn disabled'"
                    class="forgot-password"
                    style="margin-top: -5px"
                  >
                    Forgot Password
                  </p>
                  <hr style="border-color:black; margin-top: -5px" />
                  <div class="text-center" style="font-size:0.8rem;">
                    OR
                  </div>
                  <!-- <div>
                  <p
                    :class="isValid ? 'btn' : 'btn disabled'"
                  >
                    <b-button variant="outline-info" style="margin-top: -5px" href="https://accounts.google.com/ServiceLogin/signinchooser?elo=1&ifkv=AWnogHcCP5F2W9mrTMUQrlv3U4tU3Kzl-9I-uhDF1nF_wodENK6npeYckNvdwiAXg_-oTwVYlSsk&flowName=GlifWebSignIn&flowEntry=ServiceLogin" target="_parent"><b-icon-google></b-icon-google> 
                    Sign up with Google
                    </b-button>
                  </p>
                </div> -->
                <!--
                  <div class="signup-buttons" style="margin-top: 2px">
                    <a
                      :href="googleLoginUrl"
                      class="google-signup"
                      style="margin-left: 20%"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        aria-hidden="true"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <path
                            fill="#4285F4"
                            d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
                          ></path>
                          <path
                            fill="#34A853"
                            d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"
                          ></path>
                          <path
                            fill="#FBBC05"
                            d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"
                          ></path>
                          <path
                            fill="#EA4335"
                            d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"
                          ></path>
                        </g>
                      </svg>
                      Log in with Vatika Email
                    </a>
                  </div>
                -->
                </div>
              </form>
            </b-col>
          </b-row>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { validateEmail } from '@/app/services/api';
import { GOOGLE_AUTH_URL } from '../../utility/url.utility';
import URL_UTILITY from '../../utility/url.utility';
import appStrings from '../../utility/string.utility';
// import {GOOGLE_AUTH_URL, ACCESS_TOKEN} from '../../utility/googleAuth';

export default {
  mounted() {
    if (sessionStorage.token) {
      this.isAuthenticated = true;
    }
    this.$store.dispatch('auth/setToken');
    this.eventBus.$on('menutTitle', menuTitle => {
      this.activeMenu = menuTitle;
    });
    this.eventBus.$on('setLeData', results => {
      this.leList = results;
    });
  },
  watch: {
    '$route.name': function() {
      if (this.$router.currentRoute.name === 'home') {
        if (!sessionStorage.token) {
          this.isAuthenticated = false;
          this.activeMenu = null;
          this.userName = null;
          this.leList = null;
        }
      }
    }
  },
  data() {
    return {
      notificationCount: 0,
      showLoginForm: false,
      loader: false,
      activeMenu: null,
      leList: null,
      userName: null,
      userDtl: null,
      email: '',
      title: 'Login',
      password: '',
      isValid: false,
      verifyPassword: '',
      error: {},
      status: 'login',
      otp: '',
      message: '',
      reset: false,
      isAuthenticated: false,
      logoProps: { width: '80%', height: 60, class: 'm1' },
      googleLoginUrl: GOOGLE_AUTH_URL,
      downloadGstUserManualUrl: URL_UTILITY.getGstManualDocumentUrl,
      userManualGstFile: appStrings.GSTUSERMANUALFILENAME
      // googleLoginUrl:
      //   'https://realapp.in:8080/realapp/oauth2/authorize/google?redirect_uri=http://localhost:3000/oauth2/redirect'
      // googleLoginUrl:
      //   'https://realapp.in:8080/realapp/oauth2/authorize/google?redirect_uri=http://localhost:8080/oauth2/redirect'
    };
  },
  computed: {
    showUserManualLink() {
      const allowedRoutes = [
        'gstr2adwnl',
        'gstItcSummaryRpt',
        'srchitcdataupdation',
        'srchItcRec',
        'SrchPartyVerification',
        'getNearMatchForm',
        'gstr3bSummary',
        'gstItcSupplierSummaryRpt',
        'vendorCom',
        'reco_errors',
        'gst_r1_summary',
        'salesRegsiter',
        'SrchEinvoicing',
        'srchewaybill',
        'gstcredientials',
        'gstmaster',
        'gstmstmpng',
        'gstsetup',
        'vendorHoldMapping',
        'vendorEmailSetup',
        'compDash',
        'performanceMatrix',
        'company_compliance'
      ];
      return allowedRoutes.includes(this.$route.name);
    }
  },
  methods: {
    getNotificationCount() {
      const userId = this.$store.state.auth.userId;
      if (userId) {
        const payload = {
          user_id: userId
        };
        this.$store
          .dispatch('intWorkflow/getWorkflowNotiCount', payload)
          .then(response => {
            if (response.status === 200) {
              this.notificationCount = response.data.data;
            }
          });
      }
    },
    showHideLoginFrom() {
      this.showLoginForm = !this.showLoginForm;
    },
    homeIconClikced() {
      this.activeMenu = null;
      this.$store.dispatch('shared/setHomeIconClicked');
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    getUsersDetails() {
      if (sessionStorage.token) {
        this.$store.dispatch('auth/getUserDetail').then(response => {
          if (response.status === 200) {
            this.userName = response.data.data.user_name;
            this.userDtl = response.data.data;
            this.$store.dispatch('auth/setUserId', response.data.data.user_id);
            this.$store.dispatch(
              'auth/setUserName',
              response.data.data.user_name
            );
            this.$store.dispatch('auth/setUserDtl', response.data.data);
            this.getNotificationCount();
          }
        });
      }
    },
    validateEmail() {
      const email = this.email;
      const isEmailValid = validateEmail(email);
      isEmailValid
        .then(resp => {
          if (resp.status == 200) this.isValid = true;
          else {
            this.error = {
              message: 'User not found',
              user: null
            };
          }
        })
        .catch(err => {
          this.error = {
            message: 'User not found',
            user: err
          };
        });
    },
    handleOnChange: function(value) {
      this.verifyPassword = value;
      this.error = {
        setPassword: ''
      };
      this.reset = false;
    },
    handleOnComplete: function(value) {
      this.password = value;
      this.login();
    },
    handleOnCompleteSetPassword: function(value) {
      this.password = value;
      if (this.password != this.verifyPassword) {
        this.error = {
          setPassword: 'Password not match'
        };
      } else {
        this.setPassword();
      }
    },
    setPassword: function() {
      if (this.password != '' && this.verifyPassword != '') {
        const user = {
          new_password: this.password,
          user_email: this.email
        };
        this.$store
          .dispatch('auth/setNewPassword', user)
          .then(() => {
            this.status = 'login';
            this.reset = true;
          })
          .catch(err => {
            this.error = {
              newPassword: err
            };
            this.status = 'setPassword';
          });
      } else {
        this.error = {
          required: 'Password field(s) cannot be empty'
        };
      }
    },
    handleOnVerifyOTP: function(value) {
      this.error = {
        verify: ''
      };
      this.otp = value;
    },
    handleOnCompleteOTP: function(value) {
      this.otp = value;
      this.forgotPassword();
    },
    resetPassword: function() {
      const user_email = this.email;
      if (this.email != '') {
        this.$store
          .dispatch('auth/passwordReset', { user_email })
          .then(() => {
            this.title = 'Forgot Password';
            this.message = 'Request completed successfully';
            this.status = 'verify';
          })
          .catch(err => {
            this.error = {
              reset: err
            };
            this.status = 'reset';
          });
      } else {
        this.error = {
          reset: 'Email field is required'
        };
      }
    },
    forgotPassword: function() {
      const user = {
        user_email: this.email,
        otp: this.otp
      };
      this.$store
        .dispatch('auth/confirmOTP', user)
        .then(() => {
          this.status = 'setPassword';
        })
        .catch(err => {
          this.error = {
            message: err
          };
        });
    },
    login: function() {
      let user_email = this.email;
      let password = this.password;
      this.loader = true;
      this.$store
        .dispatch('auth/login', { user_email, password })
        .then(response => {
          const token = response.data.accessToken;
          // localStorage.setItem(
          //   'notification',
          //   JSON.stringify(response.data.data.notification_status)
          // );
          // this.loader = false;
          // this.isAuthenticated = true;
          // this.getUsersDetails();
          // this.showHideLoginFrom();
          // this.getNotificationCount();
          // this.$router.push('/dashboard');
          this.getResponsibility(token).then(resp => {
            sessionStorage.setItem('token', token);
            const responsibilitys = JSON.stringify(resp.data.responsibilitys);
            localStorage.setItem('responsibilities', responsibilitys);
            this.getUsersDetails();
            // this.$router.push('/dashboard');
            window.location = '/dashboard';
          });
        })
        .catch(err => {
          this.loader = false;
          this.$refs.otpInput.clearInput();
          this.error = {
            message: err
          };
        });
    },
    getResponsibility(token) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        method: 'GET'
      });
      const options = {
        headers: headers,
        method: 'GET'
      };
      const url = URL_UTILITY.responsibility;
      return fetch(url, options).then(response =>
        response.json().then(json => {
          if (!response.ok) {
            return Promise.reject(json);
          }
          return json;
        })
      );
    },
    singout: function() {
      this.$store.dispatch('auth/logout').then(() => {
        this.leList = null;
        location.reload(true);
      });
    },
    showNotificationSummary() {
      this.$router.push('/dashboard/notification/notificationSummary');
      this.activeMenu = 'Notification Summary';
    },
    showChangePasswordForm() {
      this.$router.push('/dashboard/changePassword/changePasswordForm');
      this.activeMenu = 'Change Password';
    }
  },
  created() {
    setInterval(() => {
      this.getNotificationCount();
    }, 60000);
    this.getUsersDetails();
  }
};
</script>

<style>
.form-group {
  padding: 0 15px;
}
.noti-counter {
  top: -7px;
  color: #fff;
  right: 0px;
  width: 20px;
  height: 18px;
  position: absolute;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  line-height: 18px;
  border-radius: 50%;
  background-color: #ff748e;
}
.otp-input {
  width: 100%;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.error {
  border: 1px solid red !important;
}
.user-name {
  font-weight: 600;
  padding-top: 15px;
  font-size: 0.9rem;
}
.login-section {
  display: flex !important;
}
.navbar {
  padding: 0.2rem 1rem !important;
}
.forgot-password {
  cursor: pointer;
  text-decoration: underline;
}
.nav-link {
  padding: 0.8rem 0.5rem !important;
}
.userManualBtn {
  margin-right: 20px;
  margin-top: 10px;
  padding: 0.3rem 1rem !important;
}
</style>
