const API_BASE_URL = process.env.VUE_APP_API_URL;
const WEB_BASE_URL = window.location.origin;
export const GOOGLE_AUTH_URL =
  API_BASE_URL +
  'oauth2/authorize/google?redirect_uri=' +
  WEB_BASE_URL +
  '/oauth2/redirect';
const URL_UTILITY = {
  passwordResetUrl: API_BASE_URL + 'general/reset/password/otp',
  verifyOtpUrl: API_BASE_URL + 'general/verify/otp',
  newPasswordUrl: API_BASE_URL + 'general/reset/password',
  email: API_BASE_URL + 'general/verify/email',
  responsibility: API_BASE_URL + 'responsibilities',
  // authenticateUrl: API_BASE_URL + 'authenticate',
  authenticateUrl: API_BASE_URL + 'auth/login',
  addressName: API_BASE_URL + 'admin/check/address-name/{addressName}',
  checkCountryName: API_BASE_URL + 'admin/check/country-name/{countryName}',
  checkCountryCode: API_BASE_URL + 'admin/check/country-code/{countryCode}',
  getCountryContinents: API_BASE_URL + 'admin/countries/continents',
  updateCountryDataUrl: API_BASE_URL + 'admin/countries/{countryId}',
  getAndAddCountryUrl: API_BASE_URL + 'admin/countries',
  getCountryDataPaginationUrl:
    API_BASE_URL + 'admin/countries?_page={page}&_limit={limit}&key={key}',
  getAddessDataPaginationUrl:
    API_BASE_URL + 'admin/addresses?_page={page}&_limit={limit}&key={key}',
  getFilteredAddressDataUrl:
    API_BASE_URL +
    'admin/addresses/filter?country_id={country_id}&state_id={state_id}&address_type={address_type}&_page={page}&_limit={limit}&key={key}',
  updateAddressDataUrl: API_BASE_URL + 'admin/addresses/{addressId}',
  getAddAddressDataUrl: API_BASE_URL + 'admin/addresses',
  updateStateDataUrl: API_BASE_URL + 'admin/states/{stateId}',
  getAndAddStateUrl: API_BASE_URL + 'admin/states',
  getStatesByCountryIdUrl:
    API_BASE_URL +
    'admin/countries/{countryId}/states?_page={page}&_limit={limit}&state_name={state_name}',
  getAndAddCitiesUrl: API_BASE_URL + 'admin/cities',
  getAccessRightUrl: API_BASE_URL + 'users/access-rights/{roleMenuMpngId}',
  getCitiesByStateIdUrl:
    API_BASE_URL +
    'admin/states/{stateId}/cities?_page={page}&_limit={limit}&city_name={city_name}',
  updateCitiesDataUrl: API_BASE_URL + 'admin/cities/{cityId}',
  getAndAddPincodesUrl: API_BASE_URL + 'admin/pincodes',
  getPincodesByStateIdUrl:
    API_BASE_URL +
    'admin/states/{stateId}/pincodes?_page={page}&_limit={limit}&pincode={pincode}',
  updatePincodesDataUrl: API_BASE_URL + 'admin/pincodes/{pincodeId}',
  getAddressTypesLookupUrl: API_BASE_URL + 'admin/lookup/ADDRESS_TYPE',
  getSecurityGroupLookupUrl: API_BASE_URL + 'admin/lookup/SECGRP',
  getCountryRegions: API_BASE_URL + 'admin/lookup/REGION',
  getCountriesLovUrl: API_BASE_URL + 'admin/countries/lov',
  getStatesLovByCountryIdUrl: API_BASE_URL + 'admin/states/lov/{countryId}',
  getPincodesLovByStateIdUrl: API_BASE_URL + 'admin/pincodes/lov/{stateId}',
  getCitiesLovByStateIdUrl: API_BASE_URL + 'admin/cities/lov/{stateId}',
  preferencesAddDeleteDataUrl: API_BASE_URL + 'users/preferences',
  preferencesGetDataUrl: API_BASE_URL + 'users/preferences/{preferenceId}',
  checkStateTaxCode:
    API_BASE_URL +
    'admin/check/countries/{countryId}/state-tax-code/{stateTaxCode}',
  getOrgannizationLovUrl: API_BASE_URL + 'users/org/lov/{lovType}',
  getLOVBySetTypeUrl: API_BASE_URL + 'admin/lov/{valueSetType}',
  getLOVSetTypeUrl: API_BASE_URL + 'admin/lov/value-set',
  getProjectListUrl: API_BASE_URL + 'users/projects/{typeOfProject}',
  getOtherLovByProjectIdUrl: API_BASE_URL + 'users/lov/{lovField}/{id}',
  getOptionProfileUrl: API_BASE_URL + 'admin/profiles/{profileType}',
  getReceiptFilterUrl: API_BASE_URL + 'fms/receipts',
  getFMSReceiptById: API_BASE_URL + 'fms/receipts/{receiptHdrId}',
  getPartyListUrl: API_BASE_URL + 'gl/parties',
  getPartyDetailsByIdUrl: API_BASE_URL + 'gl/parties/{partyId}',
  getLookUpDataUrl: API_BASE_URL + 'admin/lookup/{lookupType}',
  getCreatePartyUrl: API_BASE_URL + 'gl/parties',
  getSavePartyAddressUrl: API_BASE_URL + 'gl/parties/{partyId}/addresses',
  getUserDataPaginationUrl:
    API_BASE_URL +
    'admin/users?_page={page}&_limit={limit}&login_name={login_name}&name={name}&user_type={user_type}',
  getSavePartyBankUrl: API_BASE_URL + 'gl/parties/{partyId}/drawee-banks',
  getSavePartyContactDeatilUr:
    API_BASE_URL + 'gl/parties/{partyId}/contact-communications',
  getTaxGroupUrl: API_BASE_URL + 'gl/lov/tax-groups/{taxType}',
  getDirectTaxUrl: API_BASE_URL + 'gl/parties/{partyId}/direct-tax',
  getIndirectTaxUrl: API_BASE_URL + 'gl/parties/{partyId}/indirect-tax',
  getPartyEmployeeUrl: API_BASE_URL + 'hrms/lov/employee/{employeeDtl}',
  getPartyCustomerCreateUrl: API_BASE_URL + 'gl/parties/{partyId}/customer',
  getPartyVendorCreateUrl: API_BASE_URL + 'gl/parties/{partyId}/vendor',
  getCurrencyDataPaginationUrl:
    API_BASE_URL + 'admin/currencies?_page={page}&_limit={limit}&key={key}',
  updateCurrencyDataUrl: API_BASE_URL + 'admin/currencies/{currencyId}',
  addCurrencyUrl: API_BASE_URL + 'admin/currencies',
  getOrgStructureUrl: API_BASE_URL + 'admin/org-structures',
  getPartyAddressByPartyIdUrl: API_BASE_URL + 'gl/parties/{partyId}/addresses',
  getAddEditCustomerSiteUrl:
    API_BASE_URL + 'gl/customers/{customerId}/customer-sites',
  getPartyBanksListUrl: API_BASE_URL + 'gl/parties/{partyId}/banks',
  getAddEditPartyCustomerSiteBankUrl:
    API_BASE_URL + 'gl/customers/{customerId}/customer-site-banks',
  getContactsByPartyIdUrl: API_BASE_URL + 'gl/parties/{partyId}/contacts',
  getAddEditPartyCustomerContactUrl:
    API_BASE_URL + 'gl/customers/{customerId}/customer-site-contacts',
  getPartyDirectTaxListByPartyIdUrl:
    API_BASE_URL + 'gl/parties/{partyId}/direct-tax',
  getPartyIndirectTaxListByPartyIdUrl:
    API_BASE_URL + 'gl/parties/{partyId}/indirect-tax',
  getAddEditPartyCustomerDirectTaxUrl:
    API_BASE_URL + 'gl/customers/{customerId}/customer-site-direct-tax',
  getAddEditPartyCustomerIndirectTaxUrl:
    API_BASE_URL + 'gl/customers/{customerId}/customer-site-indirect-tax',
  getCustomerCcidDetailsUrl:
    API_BASE_URL + 'gl/customer-sites/{customerSiteId}/ccid-details',
  getAddEditCustomerSiteAccountUrl:
    API_BASE_URL + 'gl/customers/{customerId}/customer-site-accounts',
  getPartyVendorSiteListUrl: API_BASE_URL + 'gl/parties/{partyId}/vendor-sites',
  getVendorAddEditSiteUrl: API_BASE_URL + 'gl/vendors/{vendorId}/vendor-sites',
  getVendorBankListUrl:
    API_BASE_URL + 'gl/vendors/{vendorId}/vendor-site-banks',
  getVendorDirectTaxUrl:
    API_BASE_URL + 'gl/vendors/{vendorId}/vendor-site-direct-tax',
  getVendoreIndirectTaxUrl:
    API_BASE_URL + 'gl/vendors/{vendorId}/vendor-site-indirect-tax',
  getVendorContactListUrl:
    API_BASE_URL + 'gl/vendors/{vendorId}/vendor-site-contacts',
  getVendorCcidDetailsUrl:
    API_BASE_URL + 'gl/vendor-sites/{vendorSiteId}/ccid-details',
  getVendorSiteAccount:
    API_BASE_URL + 'gl/vendors/{vendorId}/vendor-site-accounts',
  getResponsibility: API_BASE_URL + 'admin/responsibilities',
  getRoles: API_BASE_URL + 'admin/roles',
  getModulesUrl: API_BASE_URL + 'module',
  getReportGroupUrl: API_BASE_URL + 'report-group',
  getSecurityGroupUrl: API_BASE_URL + 'admin/security-group',
  addEditResponsibilityUrl: API_BASE_URL + 'admin/responsibilities',
  addEditRoleUrl: API_BASE_URL + 'admin/roles',
  getRoleHeaderListUrl: API_BASE_URL + 'admin/roles/{roleId}',
  getMenuListUrl: API_BASE_URL + 'admin/menus',
  addEditFlexfieldUrl: API_BASE_URL + 'admin/flexfields',
  getFlexfieldUrl: API_BASE_URL + 'admin/flexfields',
  getFlexfieldHeaderListUrl: API_BASE_URL + 'admin/flexfields/{flexfieldId}',
  getFlexfieldModuleLovUrl: API_BASE_URL + 'admin/lov/flexfields/modules',
  getFlexfieldMasterLovUrl:
    API_BASE_URL + 'admin/lov/flexfields/flexfield-master',
  getFlexfieldSegmentLovUrl:
    API_BASE_URL + 'admin/lov/flexfields/flexfield-segment',
  getFlexfieldValueSetLovUrl:
    API_BASE_URL + 'admin/lov/flexfields/flexfield-valueset',
  getDocSequenceGridView: API_BASE_URL + 'admin/document-sequences',
  getPartyBankBranchUrl: API_BASE_URL + 'gl/parties/{partyId}/bank-branches',
  getPartyBankAccountUrl: API_BASE_URL + 'gl/parties/{partyId}/bank-accounts',
  getPartyBankReceiptMethodUrl:
    API_BASE_URL + 'gl/parties/{partyId}/account-receipt-methods',
  getPartyBankPaymentMethodUrl:
    API_BASE_URL + 'gl/parties/{partyId}/account-payment-methods',
  getBankAccountCcidDetailUrl:
    API_BASE_URL + 'gl/bank-accounts/{bankAccountId}/ccid-details',
  getRespHeaderListUrl:
    API_BASE_URL + 'admin/responsibilities/{responibilityHdrId}',
  getSubModuleLovUrl: API_BASE_URL + 'admin/lov/document-numbering/sub-module',
  addEditDocSequenceUrl: API_BASE_URL + 'admin/document-sequences',
  getDocNumberingGridUrl: API_BASE_URL + 'admin/document-numbering',
  getDocNumberingDetailsUrl:
    API_BASE_URL + 'admin/document-numbering/{docClassId}',
  addEditDocumentNumberingUrl: API_BASE_URL + 'admin/document-numbering',
  getMapSequenceDetailsUrl:
    API_BASE_URL + 'admin/document-numbering/map-doc-sequences/{docCatId}',
  addEditMapSequenceUrl:
    API_BASE_URL + 'admin/document-numbering/map-doc-sequences',
  getUserGridViewUrl: API_BASE_URL + 'admin/users',
  getUserResponsibilityUrl:
    API_BASE_URL + 'admin/users/{userId}/responsibilities',
  addEditUserResponsibilityUrl:
    API_BASE_URL + 'admin/users/{userId}/responsibilities',
  getUserDetailUrl: API_BASE_URL + 'users/userinfo',
  addEditUserUrl: API_BASE_URL + 'admin/users',
  getUserTypeLovUrl: API_BASE_URL + 'admin/lookup/{lookupType}',
  getCustIdByPartyIdUrl: API_BASE_URL + 'gl/parties/{partyId}/customer-id',
  getVendorIdByPartyIdUrl: API_BASE_URL + 'gl/parties/{partyId}/vendor-id',
  getCustomerDetailUrl: API_BASE_URL + 'fms/receipts/customer-details',
  getReceiptMethodsUrl: API_BASE_URL + 'admin/receipt-methods/lov',
  getReceiptOutstandingListUrl: API_BASE_URL + 'fms/receipts/outstandings',
  getTaxCategoryUrl: API_BASE_URL + 'fms/lov/tax-categories',
  getOrgParentTypeNameUrl: API_BASE_URL + 'parent-org-type/{orgTypeLookup}',
  addEditOrganizationUrl: API_BASE_URL + 'admin/org-structures',
  addEditReceiptUrl: API_BASE_URL + 'fms/receipts',
  getOrganizationDetailsUrl: API_BASE_URL + 'admin/org-structures/{orgId}',
  getTaxCategoryListUrl: API_BASE_URL + 'tax/tax-categories',
  getReceiptAdvanceDataUrl: API_BASE_URL + 'fms/receipts/advance',
  getReceiptClearUrl: API_BASE_URL + 'fms/receipts/clear',
  getReceiptAccountingUrl: API_BASE_URL + 'fms/receipts/accounting',
  getReceiptUnClearUrl: API_BASE_URL + 'fms/receipts/unclear',
  getBankClearingModeMappingGridUrl: API_BASE_URL + 'fms/bank-clearing-mode',
  getFmsProjectLovUrl: API_BASE_URL + 'users/projects/{typeOfProject}',
  getMethodTypeAndModeValueSetUrl: API_BASE_URL + 'admin/lov/{valueSetName}',
  getBankBranchIdLovUrl: API_BASE_URL + 'gl/lov/bank-branch',
  getBankAccountByBranchLovUrl:
    API_BASE_URL + 'gl/bank-accounts/{bankBranchId}',
  getPaymentMethodsUrl: API_BASE_URL + 'ar/receipts/payment-methods',
  getPartyBankDetailsUrl: API_BASE_URL + 'fms/lov/party-bank-info',
  getSourceUrl: API_BASE_URL + 'fms/lov/source',
  getBillCycleUrl: API_BASE_URL + 'fms/lov/billing-cycle',
  getFmsBillsUrl: API_BASE_URL + 'fms/bills',
  getFmsBillRequestUrl: API_BASE_URL + 'fms/lov/request',
  getFMSBillTemplateUrl: API_BASE_URL + 'fms/lov/template',
  getFMSBillDetailsUrl: API_BASE_URL + 'fms/bills/{billHdrId}',
  getTaxGroupListUrl: API_BASE_URL + 'tax/tax-groups',
  getTaxCategoryDetailsUrl: API_BASE_URL + 'tax/tax-categories/{taxCatHdrId}',
  getTaxMasterListUrl: API_BASE_URL + 'tax/tax-master',
  getTaxMasterDetailsUrl: API_BASE_URL + 'tax/tax-master/{taxMasterId}',
  getTaxSectionListUrl: API_BASE_URL + 'tax/tax-sections',
  getTaxTypeListUrl: API_BASE_URL + 'tax/tax-types',
  addEditTaxCategoryUrl: API_BASE_URL + 'tax/tax-categories',
  getFMSBillOutStandingUrl: API_BASE_URL + 'fms/bills/outstandings',
  getMFSBillDistributionUrl: API_BASE_URL + 'fms/bills/distributions',
  getRequestGroupUrl: API_BASE_URL + 'admin/request-groups',
  getRequestGroupByIdUrl: API_BASE_URL + 'admin/request-groups/{reportGroupId}',
  postRequestGroupUrl: API_BASE_URL + 'admin/request-groups',
  getRequestUrl: API_BASE_URL + 'admin/requests',
  postRequestUrl: API_BASE_URL + 'admin/requests',
  getTemplateUrl: API_BASE_URL + 'admin/templates',
  getTemplateByIdUrl: API_BASE_URL + 'admin/templates/{templateId}',
  postTemplateUrl: API_BASE_URL + 'admin/templates',
  getRequestLovUrl: API_BASE_URL + 'admin/lov/templates/requests',
  getRequestParameterUrl:
    API_BASE_URL + 'admin/templates/parameters/{templateId}',
  postRequestParameterUrl: API_BASE_URL + 'admin/templates/parameters',
  getSubmitRequestUrl: API_BASE_URL + 'admin/submit-requests',
  getRequestByUserUrl: API_BASE_URL + 'users/requests',
  addEditTaxSectionUrl: API_BASE_URL + 'tax/tax-sections',
  addEditTaxTypeUrl: API_BASE_URL + 'tax/tax-types',
  getTaxRegimeListUrl: API_BASE_URL + 'tax/tax-regimes',
  addEditTaxRegimeUrl: API_BASE_URL + 'tax/tax-regimes',
  getFMSBillDistributionUrl: API_BASE_URL + 'fms/bills/distributions',
  getFMSTransactionListUrl: API_BASE_URL + 'fms/transactions',
  getFMSTransactionLineDetailsUrl:
    API_BASE_URL + 'fms/transactions/transaction-details/{trxDtlId}',
  postSubmitRequestUrl: API_BASE_URL + 'admin/submit-requests/v2',
  getAdminValueSetUrl: API_BASE_URL + 'admin/value-sets',
  getAdminValueSetByIdUrl: API_BASE_URL + 'admin/value-sets/{valueSetHdrId}',
  getCommonValueSetDataByCodeUrl:
    API_BASE_URL + 'admin/value-set/{valueSetName}',
  getVerifyValueSetQueryUrl: API_BASE_URL + 'admin/value-sets/check',
  getAdminEmployeeListUrl: API_BASE_URL + 'hrms/employees',
  getParentValueSetUrl: API_BASE_URL + 'admin/value-sets/parent-code',
  getHrmsEmployeeUrl: API_BASE_URL + 'hrms/employee/personal-info',
  getHrmsEmpAssignmentUrl: API_BASE_URL + 'hrms/employee/assignment',
  getHrmsEmpEduQualificationUrl: API_BASE_URL + 'hrms/employee/education',
  getHrmsHrOrgHeadUrl: API_BASE_URL + 'hrms/employee/hr-org-head',
  getHrmsProfUrl: API_BASE_URL + 'hrms/employee/professional',
  getHrmsContactUrl: API_BASE_URL + 'hrms/employee/contact',
  getHrmsAddressUrl: API_BASE_URL + 'hrms/employee/address',
  getHrmsBankUrl: API_BASE_URL + 'hrms/employee/bank',
  getHrmsRelationshipUrl: API_BASE_URL + 'hrms/employee/relationship',
  getConcatSegmentUrl: API_BASE_URL + 'hrms/code-combination',
  getSuccPlanningUrl: API_BASE_URL + 'hrms/employee/success-plan',
  getHrOrgFlexfieldUrl: API_BASE_URL + 'hrms/employee/hr-flexfield',
  getWorkflowGroupUrl: API_BASE_URL + 'workflow/groups',
  getWorkflowMasterRuleUrl: API_BASE_URL + 'workflow/master-rules',
  getWorkFlowRulesUrl: API_BASE_URL + 'workflow/rules',
  getDownloadReportUrl: API_BASE_URL + 'download-report',
  getDownloadLOGUrl: API_BASE_URL + 'download-log',
  getWorkflowAppCriteriaUrl: API_BASE_URL + 'workflow/application-criteria',
  getWorkflowEventUrl: API_BASE_URL + 'workflow/events',
  getWorkFlowRuleAppCriterialUrl:
    API_BASE_URL + 'workflow/rules/application-criteria',
  getWrokflowDelegationUrl: API_BASE_URL + 'workflow/delegations',
  getDelegationDefaultEmpListUrl:
    API_BASE_URL + 'workflow/delegations/default-emp',
  getMasterProjectListUrl: API_BASE_URL + 'fms/inventory/master-projects',
  getCrmMasterProjectLovUrl: API_BASE_URL + 'fms/lov/crm-projects',
  addAndEditMasterProjectUrl: API_BASE_URL + 'fms/inventory/master-projects',
  getWorkflowNotficationUrl: API_BASE_URL + 'workflow/notifications',
  getFmsSectorListUrl: API_BASE_URL + 'fms/inventory/sectors',
  getCrmSectorLovUrl: API_BASE_URL + 'fms/lov/crm-sectors',
  addAndEditFmsSectorUrl: API_BASE_URL + 'fms/inventory/sectors',
  getFmsPocketListUrl: API_BASE_URL + 'fms/inventory/pockets',
  getCrmPocketLovUrl: API_BASE_URL + 'fms/lov/crm-pockets',
  addAndEditPocketUrl: API_BASE_URL + 'fms/inventory/pockets',
  getFmsTowerListUrl: API_BASE_URL + 'fms/inventory/towers',
  getCrmTowerLovUrl: API_BASE_URL + 'fms/lov/crm-towers',
  addAndEditTowerUrl: API_BASE_URL + 'fms/inventory/towers',
  getFmsFlooerListUrl: API_BASE_URL + 'fms/inventory/floors',
  getCrmFloorLovUrl: API_BASE_URL + 'fms/lov/crm-floors',
  addAndEditFmsFloorUrl: API_BASE_URL + 'fms/inventory/floors',
  getFmsProjectListUrl: API_BASE_URL + 'fms/projects',
  addAndEditFmsProjectUrl: API_BASE_URL + 'fms/inventory/projects',
  getIntWorkflowListUrl: API_BASE_URL + 'workflow/nfa',
  getIntWorkflowHistoryUrl: API_BASE_URL + 'workflow',
  getProfileOptionGridDataUrl: API_BASE_URL + 'admin/profile-options',
  getProfileOptionLovUrl: API_BASE_URL + 'admin/lov/profile-option/option',
  getLevelLovUrl: API_BASE_URL + 'admin/lov/profile-option/level',
  getCustomerIndirectTaxUrl:
    API_BASE_URL + 'gl/parties/{partyId}/customers/customer-indirect-tax',
  getCustomerSiteByPartyIdUrl:
    API_BASE_URL + 'gl/parties/{partyId}/customers/customer-sites',
  getCustomerBankByPartyIdUrl:
    API_BASE_URL + 'gl/parties/{partyId}/customers/customer-site-banks',
  getCustomerSiteContactByPartyIdUrl:
    API_BASE_URL + 'gl/parties/{partyId}/customers/customer-site-contacts',
  getCustomerSiteAccountByPartyIdUrl:
    API_BASE_URL + 'gl/parties/{partyId}/customers/customer-site-accounts',
  getCustomerDirectTaxUrl:
    API_BASE_URL + 'gl/parties/{partyId}/customers/customer-direct-tax',
  getHrmsCodeCombinationUrl: API_BASE_URL + 'hrms/code-combination',
  getEventIdByMenuIdUrl: API_BASE_URL + 'workflow/menu/{menuId}/events',
  getProcessWorkflowUrl: API_BASE_URL + 'workflow/process-workflow',
  getApprovalListUrl:
    API_BASE_URL + 'workflow/approval-history/{menuId}/{recordId}',
  getWorkFlowApprovalListUrl: API_BASE_URL + 'workflow/process-workflow',
  getWrkflowApprovalUrl: API_BASE_URL + 'workflow/approval',
  getOracleLeListUrl: API_BASE_URL + 'oracle/getLeList',
  getOracleOuListUrl: API_BASE_URL + 'oracle/getOuList',
  getInvoiceListURL: API_BASE_URL + 'oracleworkflow/getTrxList',
  getSavedInvoiceListURL: API_BASE_URL + 'oracleworkflow/getSavedTrxList',
  saveInvoiceApplicationURL: API_BASE_URL + 'oracleworkflow/application',
  getActionTakenUrl: API_BASE_URL + 'workflow/nfa/{nfaHdrId}/action-taken',
  getApproveRejectUrl: API_BASE_URL + 'workflow/bulk/approve-reject',
  getApproveRejectSingleUrl: API_BASE_URL + 'workflow/approve-reject',
  getWorkflowConsultUrl: API_BASE_URL + 'workflow/consult',
  getNotificationByStatusUrl:
    API_BASE_URL + 'workflow/notifications/{eventId}/{status}',
  getProjectMasterListUrl: API_BASE_URL + 'projects/master-projects',
  addEditProjectMasterUrl: API_BASE_URL + 'projects/master-projects',
  getProjectSectorListUrl: API_BASE_URL + 'projects/sectors',
  addEditProjectSectorUrl: API_BASE_URL + 'projects/sectors',
  getProjectPocketListUrl: API_BASE_URL + 'projects/pockets',
  addEditProjectPocketUrl: API_BASE_URL + 'projects/pockets',
  getProjectsProjectListUrl: API_BASE_URL + 'projects/projects',
  addEditProjectsProjectUrl: API_BASE_URL + 'projects/projects',
  getProjectsProjectAreaUrl: API_BASE_URL + 'projects/project-areas',
  addEditProjectsProjectAreaUrl: API_BASE_URL + 'projects/project-areas',
  getProjectsProjectOtherSpecUrl:
    API_BASE_URL + 'projects/project-other-specifications',
  addEditProjectsProjectOtherSpecUrl:
    API_BASE_URL + 'projects/project-other-specifications',
  getProjectsTowerListUrl: API_BASE_URL + 'projects/towers',
  addEditProjectsTowerUrl: API_BASE_URL + 'projects/towers',
  getProjectsFloorListUrl: API_BASE_URL + 'projects/floors',
  addEditProjectsFloorUrl: API_BASE_URL + 'projects/floors',
  getProjectsUnitListUrl: API_BASE_URL + 'projects/units',
  getProjectUnitAreaUrl: API_BASE_URL + 'projects/{prjUnitId}/unit-areas',
  getProjectUnitSpecUrl:
    API_BASE_URL + 'projects/{prjUnitId}/unit-specifications',
  getProjectUnitOtherSpecUrl:
    API_BASE_URL + 'projects/{prjUnitId}/unit-other-specifications',
  addEditProjectUnitUrl: API_BASE_URL + 'projects/units',
  addEditProjectUnitAreaUrl: API_BASE_URL + 'projects/unit-areas',
  addEditProjectUnitSpecUrl: API_BASE_URL + 'projects/unit-specifications',
  addEditProjectUnitOtherSpecUrl:
    API_BASE_URL + 'projects/unit-other-specifications',
  getCrmMasterProjectListUrl:
    API_BASE_URL + 'property/crm-inventories/master-projects',
  addEditCrmMasterProjectUrl:
    API_BASE_URL + 'property/crm-inventories/master-projects',
  getCrmSectorListUrl: API_BASE_URL + 'property/crm-inventories/sectors',
  addEditCrmSectorUrl: API_BASE_URL + 'property/crm-inventories/sectors',
  getWorkflowNotiCountUrl: API_BASE_URL + 'workflow/notifications/count',
  getCrmPocketListUrl: API_BASE_URL + 'property/crm-inventories/pockets',
  addEditCrmPocketUrl: API_BASE_URL + 'property/crm-inventories/pockets',
  getCrmProjectListUrl: API_BASE_URL + 'property/crm-inventories/projects',
  getCrmUnitListUrl: API_BASE_URL + 'property/crm-inventories/units',
  addEditCrmUnitUrl: API_BASE_URL + 'property/crm-inventories/units',
  getCrmProjectLicenseUrl:
    API_BASE_URL +
    'property/crm-inventories/projects/{crmProjectId}/project-license',
  getCrmProjectOtherSpecUrl:
    API_BASE_URL +
    'property/crm-inventories/projects/{crmProjectId}/project-other-specifications',
  getCrmProjectAreaUrl:
    API_BASE_URL +
    'property/crm-inventories/projects/{crmProjectId}/project-areas',
  getCrmTowerListUrl: API_BASE_URL + 'property/crm-inventories/towers',
  getCrmFloorListUrl: API_BASE_URL + 'property/crm-inventories/floors',
  addEditCrmTowerUrl: API_BASE_URL + 'property/crm-inventories/towers',
  addEditCrmFloorUrl: API_BASE_URL + 'property/crm-inventories/floors',
  addEditCrmProjectUrl: API_BASE_URL + 'property/crm-inventories/projects',
  addEditCrmProjectLicenseUrl:
    API_BASE_URL + 'property/crm-inventories/projects/project-license',
  addEditCrmProjectAreaUrl:
    API_BASE_URL + 'property/crm-inventories/projects/project-areas',
  addEditCrmProjectOtherSpecUrl:
    API_BASE_URL +
    'property/crm-inventories/projects/project-other-specifications',
  getCalendarListUrl: API_BASE_URL + 'general/calendars',
  getCalendarDetailByIdUrl: API_BASE_URL + 'general/{calHdrId}/calendars',
  getCalendarPeriodDetailUrl:
    API_BASE_URL + 'general/{calDtlId}/calendar-lines',
  getCalendarAddEditUrl: API_BASE_URL + 'general/calendar-lines',
  getSOBUrl: API_BASE_URL + 'general/sob',
  getSOBCalenderLov: API_BASE_URL + 'general/sob/lov/calendars',
  getGeneralPeriodsUrl: API_BASE_URL + 'general/periods',
  getProfileOptionByIdUrl:
    API_BASE_URL + 'admin/profile-options/{profileOptionHdrId}',
  getLookupUrl: API_BASE_URL + 'admin/lookup',
  getFmsMasterBillingUrl: API_BASE_URL + 'fms/master-bills',
  getFmsComponentGroupUrl: API_BASE_URL + 'fms/component-groups',
  addEditProfileOptionHdrUrl: API_BASE_URL + 'admin/profile-options',
  addEditProfileOptionDtlUrl: API_BASE_URL + 'admin/profile-options/details',
  getFmsComponentMasterUrl: API_BASE_URL + 'fms/component-masters',
  getOpenKMSavePasswordUrl: API_BASE_URL + 'openkm/saved-password-flag',
  getOpenKmAuthenticationUrl: API_BASE_URL + 'openkm/authenticate',
  getOpenKmPartyPortalAuthenticateUrl:
    API_BASE_URL + 'openkm/party-portal/authenticate',
  getOpenKmUploadDocUrl: API_BASE_URL + 'openkm/upload-doc',
  getOpenKmDocDtlUrl: API_BASE_URL + 'openkm/docs',
  getOpenKmDocDeleteUrl: API_BASE_URL + 'openkm/doc',
  getOpenKmLogoutUrl: API_BASE_URL + 'openkm/logout',
  getWfReminderUrl: API_BASE_URL + 'workflow/reminder-history',
  getFmsBillingPlanUrl: API_BASE_URL + 'fms/billing-plans',
  getBulkApproveRejectUrl: API_BASE_URL + 'workflow/bulk/approve-reject',
  getNfaActionTakeUrl: API_BASE_URL + 'workflow/nfa/action-taken',
  getBillingPlanSubUnitUrl:
    API_BASE_URL + 'fms/billing-plans/billing-plan-subunits',
  getFmsInterestRateUrl: API_BASE_URL + 'fms/interest-rates',
  getFlexfieldAccountingUrl: API_BASE_URL + 'general/accounting-flexfield',
  getMenuMappingListUrl: API_BASE_URL + 'admin/menus-mapping',
  addEditMenuMappingRecordUrl: API_BASE_URL + 'admin/menus-mapping',
  getInventoryLocationMappingListUrl:
    API_BASE_URL + 'admin/inventory-location-mapping',
  getInventoryLocationByIdUrl:
    API_BASE_URL + 'admin/inventory-location-mapping/{inventoryLocMapId}',
  getLeaseProjectsListUrl: API_BASE_URL + 'lease/lease-projects',
  leaseAdminUrl: API_BASE_URL + 'lease',
  getFmsGenBatchUrl: API_BASE_URL + 'fms/batch-generate',
  getLeaseTowersListUrl: API_BASE_URL + 'lease/lease-towers',
  getTwoSixASListUrl: API_BASE_URL + 'AS26/AS26-viewDetails',
  getPeriodStartListUrl: API_BASE_URL + 'AS26/AS26-periodByFinancialYear',
  getFinYearListUrl: API_BASE_URL + 'AS26/AS26-financialYear',
  getPanNumForTwoSixUploadUrl: API_BASE_URL + 'AS26/AS26-panByLegalEntity',
  getTempDataUrl: API_BASE_URL + 'AS26/AS26-displayTempData',
  postTempDataUrl: API_BASE_URL + 'ExcelUpload/saveDataTmp',
  postTempDataExcelUrl: API_BASE_URL + 'AS26/AS26-saveDataAs26',
  postTempAssetUrl: API_BASE_URL + '',
  getRowDataByIdUrl: API_BASE_URL + 'AS26/AS26-viewDetailsOnClick',
  getCustomerTanDataUrl: API_BASE_URL + 'AS26/AS26-getTANDetails',
  getValuesetDetailsUrl:
    API_BASE_URL + 'admin/value-set/{valueSetName}/details',
  addEditLeaseProjectUrl: API_BASE_URL + 'lease/lease-projects',
  getLeaseFloorListUrl: API_BASE_URL + 'lease/lease-floors',
  addEditLeaseFloorListUrl: API_BASE_URL + 'lease/lease-floors',
  getLeaseUnitListUrl: API_BASE_URL + 'lease/lease-units',
  addEditLeaseTowersListUrl: API_BASE_URL + 'lease/lease-towers',
  addEditLeaseUnitUrl: API_BASE_URL + 'lease/lease-units',
  getSecGroupDetailByIdUrl:
    API_BASE_URL + 'admin/security-groups/{securityGroupHdrId}',
  getFmsBatchBillUrl: API_BASE_URL + 'fms/bill-batches',
  getSecGroupMasterListUrl: API_BASE_URL + 'admin/security-groups',
  getFmsBatchBillGenUrl:
    API_BASE_URL + 'admin/bill-generation/submit-requests/v2',
  addEditSecGroupUrl: API_BASE_URL + 'admin/security-groups',
  getLeaseUnitAreaUrl: API_BASE_URL + 'lease/lease-unit-areas/{leasePrjUnitId}',
  addEditLeaseUnitAreaUrl: API_BASE_URL + 'lease/lease-unit-areas',
  getLeaseUnitSpecificationUrl:
    API_BASE_URL + 'lease/lease-unit-specifications/{leasePrjUnitId}',
  addEditLeaseUnitSpecificationUrl:
    API_BASE_URL + 'lease/lease-unit-specifications',
  getLeaseUnitOtherSpecificationUrl:
    API_BASE_URL + 'lease/lease-unit-other-specifications/{leasePrjUnitId}',
  addEditLeaseUnitOtherSpecificationUrl:
    API_BASE_URL + 'lease/lease-unit-other-specifications',
  getGstCredentialsListUrl: API_BASE_URL + 'gst/gst-credentials',
  getGstMasterListUrl: API_BASE_URL + 'gst/gst-master',
  getGstrTwoADownloadListUrl: API_BASE_URL + 'gst/gst-data-sync',
  getGstrTwoADownloadByIdUrl:
    API_BASE_URL + 'gst/refresh-request/{gst_credential_id}',
  getRefreshForGstrByIdUrl:
    API_BASE_URL + 'gst/referesh-request/{gst_credential_id}',
  getLeaseSubUnitListUrl: API_BASE_URL + 'lease/lease-sub-units',
  getLeaseSubUnitDetailsByIdUrl:
    API_BASE_URL + 'lease/lease-sub-units/{leasePrjUnitId}',
  getLeaseUnitDetailsByIdUrl:
    API_BASE_URL + 'lease/lease-units/{leasePrjUnitId}',
  getComponentDetailsByIdUrl: API_BASE_URL + 'fms/component-masters',
  getTaxCategoryLovUrl: API_BASE_URL + 'fms/lov/tax-categories',
  getAutoAccountingListUrl: API_BASE_URL + 'fms/auto-accounting',
  getAutoAccountingListByIdUrl:
    API_BASE_URL + 'fms/auto-accounting/{fmsAutoAccountingRuleHdrId}',
  addUploadExcelListUrl: API_BASE_URL + 'ExcelUpload/postExcel',
  getOtpForGstrDownloadUrl: API_BASE_URL + 'gst/getotp',
  getFmsUnitGridListUrl: API_BASE_URL + 'fms/inventory/units',
  getFmsSubUnitGridListUrl: API_BASE_URL + 'fms/inventory/sub-units',
  getFmsUnitOriginalAreaUrl:
    API_BASE_URL + 'fms/inventory/unit-area/{fmsUnitId}',
  getFmsSubUnitOriginalAreaUrl:
    API_BASE_URL + 'fms/inventory/sub-unit-original-area/{fmsSubUnitId}',
  getFmsSubUnitRevisedAreaUrl:
    API_BASE_URL + 'fms/inventory/sub-unit-revised-area/{fmsSubUnitId}',
  getFmsSubUnitSpecificationUrl:
    API_BASE_URL + 'fms/inventory/sub-unit-specification/{fmsSubUnitId}',
  getFmsSubUnitCustomerListUrl:
    API_BASE_URL + 'fms/inventory/sub-unit-customer/{fmsSubUnitId}',
  getLeaseMasterAgreementUrl: API_BASE_URL + 'lease/lease-master-agreement',
  getValidateOtpForGstrDownloadUrl: API_BASE_URL + 'gst/validateotp',
  getSyncGstrDownloadUrl: API_BASE_URL + 'gst/fetchdata',
  getPaymentAdviceListUrl: API_BASE_URL + 'oracleworkflow/getPmtAdviceList',
  getMappingColumnsForExcelDetailUrl:
    API_BASE_URL + 'ExcelUpload/getMappedColumns',
  getMappingColumnsDataListForExcelDetailUrl:
    API_BASE_URL + 'ExcelUpload/getExcelTempData',
  postMappedColumnsForExcelUrl: API_BASE_URL + 'ExcelUpload/saveExcelMapping',
  getPaymentInvoiceListUrl:
    API_BASE_URL + 'oracleworkflow/getPaymentInvoiceList',
  getLeaseTenantUrl: API_BASE_URL + 'lease/lease-tenant-agreement',
  getFmsUnitOwnershipDataUrl:
    API_BASE_URL + 'fms/inventory/unit-party/{fmsUnitId}',
  addEditFmsSubUnitUrl: API_BASE_URL + 'fms/inventory/sub-unit',
  addEditFmsSubUnitRevisedAreaUrl:
    API_BASE_URL + 'fms/inventory/sub-unit-area/{fmsSubUnitId}',
  getCustomerSiteUrl: API_BASE_URL + 'gl/customers',
  createSubunitFromUnitIdUrl:
    API_BASE_URL + 'fms/inventory/unit/sub-unit/{fmsUnitId}',
  getSubUnitDetailsByIdUrl:
    API_BASE_URL + 'fms/inventory/sub-unit/{fmsSubUnitId}',
  getItcSummaryReportUrl: API_BASE_URL + 'gst/gst-summary',
  getFlexfieldLegalEntityMpngUrl:
    API_BASE_URL + 'admin/le-flexfield-mpng/{flexfieldId}',
  getDependentValueSetByParentValuesetUrl:
    API_BASE_URL + 'admin/dependent-value-sets/{parentValueSetHdrId}',
  getLeaseInvestorUrl: API_BASE_URL + 'lease/lease-investor-agreement',
  getTenantScheduleUrl: API_BASE_URL + 'lease/lease-tenant-schedule',
  getSourceNameListUrl: API_BASE_URL + 'admin/admin-source/lov',
  getTxnTypeListUrl: API_BASE_URL + 'admin/trx-type/lov/{moduleId}',
  getLeaseSchTemplateUrl:
    API_BASE_URL + 'admin/lease-schd-template/submit-requests/v2',
  getLeaseGenerateTenantBatchUrl: API_BASE_URL + 'lease/process-invoice/tenant',
  getLeaseProcessInvoiceUrl:
    API_BASE_URL + 'admin/lease-generate-bill/submit-requests/v2',
  getLeaseProcessInvoiceForInvestorUrl:
    API_BASE_URL + 'admin/lease-generate-investor-bill/submit-requests/v2',
  getTenantLeaseInvoiceUrl: API_BASE_URL + 'lease/lease-invoices/tenant',
  getArTrsansactionUrl: API_BASE_URL + 'ar/transactions',
  getSubModuleListUrl: API_BASE_URL + 'admin/sub-modules',
  getUpdatePeriodDetailUrl: API_BASE_URL + 'general/periods-details',
  getInvestorScheduleUrl: API_BASE_URL + 'lease/lease-investor-schedule',
  getSubModuleListByIdUrl: API_BASE_URL + 'admin/sub-modules/{moduleId}',
  getLeaseTenantInvoiceSubmitRequestUrl:
    API_BASE_URL + 'admin/tenant-lease-create-trxs/{ouId}/submit-requests/v2',
  getTenantManualBillProcessUrl:
    API_BASE_URL +
    'admin/tenant-lease-manual-create-trxs/{ouId}/submit-requests/v2',
  getInvestorLeaseInvoiceUrl: API_BASE_URL + 'lease/lease-invoices/investor',
  getOpenCloseFiltersDataByLeId:
    API_BASE_URL + 'general/period-hdr/{leId}/company',
  getProcessInvoiceInvestorUrl: API_BASE_URL + 'lease/process-invoice/investor',
  getCodeCombinationUrl: API_BASE_URL + 'admin/code-combinations',
  getCodeCombinationColumnsByFlexfieldUrl:
    API_BASE_URL + 'admin/code-combination/segment-column-mpng',
  getTaxRegimeRegistrationUrl: API_BASE_URL + 'tax/tax-regimes-registration',
  getInvoiceEntryListUrl: API_BASE_URL + 'ap/invoices',
  getTaxRegimeAccountUrl: API_BASE_URL + 'tax/tax-regime-ac',
  getArReceiptsUrls: API_BASE_URL + 'ar/receipts',
  getLeaseInvestorRecoveryUrl:
    API_BASE_URL + 'lease/lease-investor-recovery-batch',
  getLeaseTenantRecoveryUrl: API_BASE_URL + 'lease/lease-tenant-recovery-batch',
  getProjectBasedLocationUrl: API_BASE_URL + 'location-name',
  getTrxEntityTypeByModuleIdUrl:
    API_BASE_URL + 'admin/{moduleId}/trx-entity-type',
  getPaymentBatchUrls: API_BASE_URL + 'ap/payment-batch',
  getInvoicesByPaymentIdUrl: API_BASE_URL + 'ap/payment/{paymentId}/invoices',
  getInvestorRecoveryBatchInvoiceById:
    API_BASE_URL + 'lease/lease-investor-recovery-batch/{batchId}/invoices',
  getInvestorRecoveryBatchProcessById:
    API_BASE_URL + 'lease/lease-investor-recovery-batch/{batchId}/process',
  getTenantRecoveryBatchInvoiceById:
    API_BASE_URL + 'lease/lease-tenant-recovery-batch/{batchId}/invoices',
  getArApplyReceiptTransactionUrl: API_BASE_URL + 'ar/apply/receipts-trx',
  processTenantRecoveryDetailsUrl:
    API_BASE_URL + 'lease/lease-tenant-recovery-batch/{batchId}/process',
  processTenantRecoveryUrl:
    API_BASE_URL + 'lease/lease-tenant-recovery-batch/process',
  postInvestorScheduleRequestUrl:
    API_BASE_URL + 'admin/lease-investor-schedule/submit-requests/v2',
  processInvestorRecoveryRequestUrl:
    API_BASE_URL + 'admin/lease-process-recovery/{type}/submit-requests/v2',
  getManualPaymentGridUrl: API_BASE_URL + 'ap/manual-payments',
  getPeriodDefaultDateUrl: API_BASE_URL + 'open-period-date',
  getValidatePeriodDateUrl: API_BASE_URL + 'validate-period-date',
  postApInvoicesInvestorBillRequestUrl:
    API_BASE_URL + 'admin/ap-create-invoice/submit-requests/v2',
  getCustomerQueryValueSet: API_BASE_URL + 'customer/valueset/{moduleId}',
  getTanRateDetailsUrl: API_BASE_URL + 'AS26/AS26-getTANRateDetails',
  getBankMethodDetailsUrl: API_BASE_URL + 'gl/bank-mode-methods',
  getHostToHostBankTransferGridUrl: API_BASE_URL + 'ap/h2h/bank-batch',
  getBankBatchLovUrl: API_BASE_URL + 'ap/lov/bank-batch',
  getPaymentBatchLovUrl: API_BASE_URL + 'ap/lov/payment-batch',
  getPaymentSeriesUrl: API_BASE_URL + 'gl/bank-accounts',
  getSkipSeriesUrl: API_BASE_URL + 'gl/payment-series',
  getPaymentMethodsLovUrl: API_BASE_URL + 'admin/payment-methods/lov',
  getLeBasedNaturalAccountVsetUrl:
    API_BASE_URL + 'admin/accounting-flexfield/{leId}/natural-account-vset',
  getProcessedPaymentDetailsUrl: API_BASE_URL + 'ap/h2h/approved/bank-payments',
  getErrorPaymentDetailsUrl: API_BASE_URL + 'ap/h2h/error/bank-payments',
  getAdviseStatusGridUrl: API_BASE_URL + 'ap/payment-advice',
  getAccountMappingUrl: API_BASE_URL + 'tax/tax-regime-mapping-account-details',
  getTwoSixASInvoiceUrl: API_BASE_URL + 'AS26/AS26-getOracleInvoices',
  getApiRegistrationGridUrl: API_BASE_URL + 'api',
  getRemainingAmountForOsUrl: API_BASE_URL + 'ar/get-remaining-amount',
  getInvItemsGridUrl: API_BASE_URL + 'inventory/items',
  getInvMaintainLot: API_BASE_URL + 'inventory/maintain-lots',
  getFmsSubUnitDetailsByUnitIdUrl:
    API_BASE_URL + 'fms/inventory/unit/{fmsUnitId}/sub-unit-mpng',
  getNfaGridListUrl: API_BASE_URL + 'workflow/sources',
  getNfaHdrDetailsUrl: API_BASE_URL + 'workflow/source',
  getPayableInvoiceListUrl: API_BASE_URL + 'pp/vendors/invoices',
  getQueryAssignmentUrl: API_BASE_URL + 'customer/assign-query',
  getCustomerQueryListUrl: API_BASE_URL + 'customer/queries',
  approveRejectPayableInvoiceUrl: API_BASE_URL + 'pp/vendor-invoice-details',
  getModuleBasedValuesetNameUrl:
    API_BASE_URL + 'customer/entity-type/{moduleId}/valuesets',
  getPartyPortalUpdateStatusUrl: API_BASE_URL + 'pp/party',
  approveRejectPartyPortalDetailsUrl: API_BASE_URL + 'pp/party-details',
  setArTransactionToCompleteUrl: API_BASE_URL + 'ar/trx/complete',
  getPartyVerificationUrl: API_BASE_URL + 'gst/gstnumber-verification',
  approveRejectPartyPortalAddressUrl: API_BASE_URL + 'pp/party-address',
  approveRejectPartyPortalBankUrl: API_BASE_URL + 'pp/party-drawee-banks',
  approveRejectPartyPortalContactDetailsUrl:
    API_BASE_URL + 'pp/party-contact-person',
  approveRejectPartyPortalContactCommunicationUrl:
    API_BASE_URL + 'pp/party-contact-comm',
  approveRejectPartyPortalDirectTaxUrl: API_BASE_URL + 'pp/party-direct-tax',
  approveRejectPartyPortalIndirectTaxUrl:
    API_BASE_URL + 'pp/party-indirect-tax',
  getItcDataUpdationUrl: API_BASE_URL + 'gst/gst-upgrades',
  changePasswordUrl: API_BASE_URL + 'general/change/password',
  getAssetsUrl: API_BASE_URL + 'fa/assets',
  getTransferAssetsUrl: API_BASE_URL + 'fa/transfer-batches',
  getItcReconciliationUrl: API_BASE_URL + 'gst/gst-reco-detail',
  getReconcileDetailsUrl: API_BASE_URL + 'gst/gst-reco-tab-2bdata',
  getReconcileDetailUrl: API_BASE_URL + 'gst/gst-reco-tab-recodata',
  RecoFormActionUrl: API_BASE_URL + 'gst/gst-reco-tab-action',
  getReceiveAssetBatchUrl: API_BASE_URL + 'fa/receive-batches',
  getLocationBasedGstnUrl: API_BASE_URL + 'locations/{locId}/gstn',
  verifyGstNumberUrl: API_BASE_URL + 'gst/verify_gst_number',
  getCalculatedLeaseRecoveryAmountUrl:
    API_BASE_URL + 'lease/calculate/lease-recovery-amount',
  getGstAvaliableTabUrl: API_BASE_URL + 'gst/gst-available-tab-searchdata',
  getGstAvaliableTabActionUrl: API_BASE_URL + 'gst/gst-available-tab-action',
  getMutationListUrl: API_BASE_URL + 'fms/mutation',
  getUnitOwnerShipDetailsUrl:
    API_BASE_URL + 'fms/inventory/unit/{fmdUnitId}/ownrship-details',
  getDunningCommonSetupListUrl: API_BASE_URL + 'dunning/setup-list',
  getDunningRuleMasterListUrl: API_BASE_URL + 'dunning/rule-master-list',
  getDunningRuleMasterDetailsListUrl:
    API_BASE_URL + 'dunning/rule-master-detail-list',
  getDunningLetterMasterUrl: API_BASE_URL + 'dunning/letter-master-list',
  getDunningCommonSetupGridUrl: API_BASE_URL + 'dunning/get-setup-by-id',
  DunningAddUpdateSetupUrl: API_BASE_URL + 'dunning/add-update-setup',
  getDunningRuleMasterGridListUrl:
    API_BASE_URL + 'dunning/get-rule-master-by-id',
  getDunningLetterMasterGridListUrl:
    API_BASE_URL + 'dunning/get-letter-master-by-id',
  getCostCenterGridDataUrl: API_BASE_URL + 'rgp/cost-center/requests',
  getCostCenterDataByHdrIdUrl: API_BASE_URL + 'rgp/cost-center',
  getWareHouseGridDataUrl: API_BASE_URL + 'rgp/warehouse/requests',
  getWareHouseDataByHdrIdUrl: API_BASE_URL + 'rgp/warehouse',
  getDunningCommonSetupDataByIdUrl: API_BASE_URL + 'dunning/get-setup-by-id',
  addUpdateCommonSetupDataUrl: API_BASE_URL + 'dunning/add-update-setup',
  getDunningRuleMasterDataByIdUrl:
    API_BASE_URL + 'dunning/get-rule-master-by-id',
  getDunningRuleMasterDetailsDataByIdUrl:
    API_BASE_URL + 'dunning/get-rule-master-detail-by-id',
  addUpdateRuleMasterDataUrl: API_BASE_URL + 'dunning/add-update-rule-master',
  addUpdateRuleMasterDetailsDataUrl:
    API_BASE_URL + 'dunning/add-update-rule-master-detail',
  getDunningLetterMasterDetailsGridListUrl:
    API_BASE_URL + 'dunning/letter-master-conf-list',
  getInvestorRecoveryBatchUnProcessById:
    API_BASE_URL + 'lease/lease-investor-recovery-batch/{batchId}/unprocess',
  getSyncTransactionAssetListDataUrl:
    API_BASE_URL + 'fa/transaction/receive-assets',
  postSyncTransactionProcessAssetListDataUrl:
    API_BASE_URL + 'fa/transaction/syncAsset',
  getTenantRecoveryBatchUnProcessById:
    API_BASE_URL + 'lease/lease-tenant-recovery-batch/{batchId}/unprocess',
  getCustomerQueryEmployeeListUrl:
    API_BASE_URL + 'customer/queries/{ccQueryId}/employee-details',
  getCustomerQueryAssignedListUrl:
    API_BASE_URL + 'customer/queries/{ccQueryId}/assignee-details',
  getCustomerQueryTaskAssignedListUrl:
    API_BASE_URL + 'customer/queries/{queryTaskId}/task-assignee',
  getDunningCustomerRuleMappingListUrl:
    API_BASE_URL + 'dunning/customer-rule-mapping-list',
  getCustomerQueryRaisedByUrl: API_BASE_URL + 'customer/lov/userId/email',
  getDunningCustomerRuleMappingDataByIdUrl:
    API_BASE_URL + 'dunning/get-customer-rule-mapping-by-id',
  addEditCustomerRuleMappinngDataUrl:
    API_BASE_URL + 'dunning/addUpdateCustomerRuleMapping',
  assetTaxDetailsUrl: API_BASE_URL + 'tax',
  getTransferAssetTmpDetailsDataUrl: API_BASE_URL + 'fa/transfer-excel-batches',
  addUploadExcelAssetListUrl:
    API_BASE_URL + 'fa/transfer-excel-batches/saveDataTmp',
  addEditInvoiceLineDataUrl:
    API_BASE_URL + 'ap/invoices/{invoiceId}/update-invoice-line',
  postUpdateLocationProcessDataUrl:
    API_BASE_URL + 'fa/transfer-excel-batches/transfer-process',
  getCustomerCriteriaMappingListUrl:
    API_BASE_URL + 'dunning/list-dunning-customer-criteria',
  getPartySubMenuIdUrl: API_BASE_URL + 'pp/party-sub-menus',
  getInvoiceAccountingListUrl:
    API_BASE_URL + 'ap/invoices/{invoiceId}/accounting',
  getdunningHeaderSummaryGridListUrl:
    API_BASE_URL + 'dunning/list-dunning-header',
  addEditCustomerCriteriaMappingDataUrl:
    API_BASE_URL + 'dunning/addUpdateDunningCustomerCriteria',
  getDunningHeaderDetailByIdUrl:
    API_BASE_URL + 'dunning/getDunningHeaderDetailById',
  validateApInvoiceDataUrl: API_BASE_URL + 'ap/invoices/{invoiceId}/validate',
  getInvoicePaymentsListUrl: API_BASE_URL + 'ap/invoices/{invoiceId}/payments',
  cancelApInvoiceDataUrl: API_BASE_URL + 'ap/invoices/{invoiceId}/cancel',
  addEditScheduleLineDataUrl:
    API_BASE_URL + 'ap/invoices/{invoiceId}/update-invoice-schedule',
  getTransactionAccountingListUrl:
    API_BASE_URL + 'ar/receipts/{receiptHdrId}/accounting',
  getGstrThreeBSummaryTabDataUrl: API_BASE_URL + 'gst/gstr3b-summary',
  getFinancialYearDataUrl: API_BASE_URL + 'financial-year',
  getSampleFile: API_BASE_URL + 'download-assetTrfSamplefile',
  getGstrThreeBTabsDataUrl: API_BASE_URL + 'gst/gstr3b-summary-detail',
  getPrintTransferAssetUrl: API_BASE_URL + 'delhivery-print-fa-invoice',
  getActivityDetailsUrl: API_BASE_URL + 'ar/receipts',
  getVendorSiteByOuUrl: API_BASE_URL + 'gl/vendors',
  getCustomerSiteByOuUrl: API_BASE_URL + 'gl/customers',
  getVendorDetailsByUnitUrl: API_BASE_URL + 'customer/vendor-details',
  getCustomerDetailsByUnitUrl: API_BASE_URL + 'customer/customer-details',
  saveEmailInTempTableUrl: API_BASE_URL + 'customer',
  downloadQueryDocumentDocUrl: API_BASE_URL + 'customer/comm/download-doc',
  resendMailDunningHeaderSummaryUrl: API_BASE_URL + 'dunning',
  getValueSetForCodeCombinationUrl:
    API_BASE_URL + 'admin/segment-valueset-mappings',
  getArCofDataUrl: API_BASE_URL + 'ar/cof',
  getReceiptNotesById: API_BASE_URL + 'ar/receipts',
  getCreatePvJobBatchUrl: API_BASE_URL + 'fa/create-pv-job-batches',
  postBulkWorkflow: API_BASE_URL + 'workflow/bulk/approval',
  addEditBillDetailsUrl: API_BASE_URL + 'fms/adhoc-bills',
  updateGstnVerificationUrl: API_BASE_URL + 'gst/gstnVerificationUpdate',
  getTaxDetailsByIdUrl: API_BASE_URL + 'tax/taxes',
  getEinvoiceDetailsByIdUrl: API_BASE_URL + 'gst/einvoice/status',
  getIrnRequestUrl: API_BASE_URL + 'fa/irn-invoice-req',
  updateTenantBillUrl: API_BASE_URL + 'lease/lease-invoices/update/po',
  gstPartyUploadExcelUrl: API_BASE_URL + 'gst/external-gstn-upload',
  addEditArTransactionUrl: API_BASE_URL + 'ar/trx',
  getInternalContactDetailsUrl: API_BASE_URL + 'hrms/lov/employee/details',
  getPartyOnBoardingDetailsUrl: API_BASE_URL + 'gl/party-on-boarding',
  getVendorAddressDetailsUrl: API_BASE_URL + 'gl/party-address-request',
  getVendorContactDetailsUrl: API_BASE_URL + 'gl/party-contact-request',
  getVendorBankDetailsUrl: API_BASE_URL + 'gl/party-bank-request',
  updateGstRecoUrl: API_BASE_URL + 'gst/gstRecoUpdate',
  getPvJobSampleFile: API_BASE_URL + 'download-assetCreatePvSamplefile',
  getArCofSampleFile: API_BASE_URL + 'download-cofSamplefile',
  addEditTenantBillUrl:
    API_BASE_URL + 'lease/lease-invoices/update/billLineDesc',
  getSupplierSummaryUrl: API_BASE_URL + 'gst/supplier_reco_summary',
  getGstNearMatchDetailsUrl: API_BASE_URL + 'gst/near-match',
  getPreEvaluationSupplierUrl: API_BASE_URL + 'gl/evaluation-party-req',
  getVendorComplianceDetailsUrl: API_BASE_URL + 'gl/party-compliance',
  getComplainceGridListUrl: API_BASE_URL + 'gst/gstR2b',
  updateNearMatchUrl: API_BASE_URL + 'gst/nearMatchUpdate',
  getComplainceGstrTwoGridListUrl: API_BASE_URL + 'gst/gstR2',
  updateIrnRequestUrl: API_BASE_URL + 'fa/submit-requests/irn-regenerate-fa',
  billingRequestGenerateUrl:
    API_BASE_URL + 'admin/fms-create-trxs/submit-requests',
  getPartyAccDetailsUrl: API_BASE_URL + 'gl/party-acc/setup-dtls',
  addEditPartyAccDetailsUrl: API_BASE_URL + 'gl/party-req-acc-details',
  getAccountingDetailsUrl: API_BASE_URL + 'gl/acc-detail/accounting-setup',
  getAdditionalDetailsUrl: API_BASE_URL + 'gl/party-req-additional-dtls',
  checkPanVerifyUrl: API_BASE_URL + 'verify/pan-verify',
  isVendorExistUrl: API_BASE_URL + 'gl/oracle-pan-details',
  getBankDetailsByIdUrl: API_BASE_URL + 'ar/receipt-method',
  getGstPerformanceMatrix: API_BASE_URL + 'gst/gst',
  getAllDocumentsByIdUrl: API_BASE_URL + 'gl/party-req',
  uploadPurchaseRegisterUrl: API_BASE_URL + 'gst/gst-manual-uploader',
  downloadDocUrl: API_BASE_URL + 'download-vendor-portal-documents-files',
  getFmsAdminUrl: API_BASE_URL + 'fms',
  getComplianceDashboardTab3Url: API_BASE_URL + 'gst/gstR3b',
  getInvestorPaymentUrl: API_BASE_URL + 'lease/investor-payment-status',
  resendEmailPartyonboardingUrl:
    API_BASE_URL + 'gl/party-on-board/resend-email',
  getAwsBackupUrl: API_BASE_URL + 'gst/aws-backup',
  getFaLocationUrl: API_BASE_URL + 'fa',
  addEditLeaseAgrmntDtlsUrl: API_BASE_URL + 'realdb/lease-tenant-agreement-dtl',
  getRequestTemplateIdUrl: API_BASE_URL + 'lease-agrement/requestTempId',
  updateAgreementReqIdUrl:
    API_BASE_URL + 'lease/realdb-lease-tenant-agreement/update-req',
  previewLeaseAgrmntRptUrl: API_BASE_URL + 'preview-report',
  getGstUrl: API_BASE_URL + 'gst',
  getProcessTransferAssetUrl: API_BASE_URL + 'fa/submit-requests',
  getCollectionMgmtSystemUrl: API_BASE_URL + 'cms',
  getExpenseReportUrl: API_BASE_URL + 'expense-report',
  getGrnAssetUrl: API_BASE_URL + 'fa/asset-master',
  createGrnInvoiceApprovalUrl: API_BASE_URL + 'grn/grn-insert',
  generateRequestUrl: API_BASE_URL + 'admin',
  getValueSetUrl: API_BASE_URL + 'vendor-portal',
  getVendorInvoiceDetailsUrl: API_BASE_URL + 'gl/vendor-invoice-entry',
  getVendorInvoiceDetailsByIdUrl: API_BASE_URL + 'gl/vendor-invoices-entry',
  getVendorDetailsUrl: API_BASE_URL + 'gl/vendor-id',
  getAssetSampleFile: API_BASE_URL + 'download-grnAssetUploadSamplefile',
  uploadExcelApInvoiceEntryUrl: API_BASE_URL + 'ap/due-date',
  getFusionUrl: API_BASE_URL + 'fusion',
  approveForwardInvoiceUrl: API_BASE_URL + 'workflow/approve-forward',
  authLogoutUrl: API_BASE_URL + 'auth/logout',
  verify2FAOTPUrl: API_BASE_URL + 'general/verify/2fa',
  resend2FAOTPUrl: API_BASE_URL + 'general/resend/otp',
  gmailAuthUrl: API_BASE_URL + 'auth/is-gmail-auth',
  getAdminSourceUrl: API_BASE_URL + 'admin/adminSource',
  uploadExcelReceiptAccUrl: API_BASE_URL + 'ar/receipt-loader-excelupload',
  getRecieptUploadDataUrl: API_BASE_URL + 'ar/get-receipt-loader',
  getGstManualDocumentUrl: API_BASE_URL + 'gst/gst-user-manual',
  updateFmsBillableAreaUrl: API_BASE_URL + 'lease/lease-unit-fmsbillable-area',
  getRfpAdminUrl: API_BASE_URL + 'rfp',
  getInvoiceSampleFile: API_BASE_URL + 'download-vendor-portal-documents-files',
  getAuditRemedyMenuId:API_BASE_URL + 'admin',
  updateNarrationUrl: API_BASE_URL + 'ar/edit-receipt_narration',
  uploadDocumentUrl: API_BASE_URL + 'admin/upload-doc/fms',
  getCodUrl: API_BASE_URL + 'cod',
  reverseTrxUrl: API_BASE_URL + 'ar/transaction/reverse',
  getUnitSubunitUrl: API_BASE_URL + 'fms/get-updated-enviro-billing-area-by-id',
  updateUnitSubunitEnviroAreaUrl:
    API_BASE_URL + 'fms/update-unit-subunit-enviro-area',
  updateBillHoldUnholdUrl: API_BASE_URL + 'fms/bills/is-hold-update',
  billUploadDocUrl: API_BASE_URL + 'fms/bills-excel-upload',
  getBillUploadUrl: API_BASE_URL + 'fms/get-bill-loader',
  getRfpItemSampleFile: API_BASE_URL + 'download-rfpItemSamplefile',
  getRfpBoqSampleFile: API_BASE_URL + 'download-rfpBoqSamplefile',
  getCodeCombinationNewUrl: API_BASE_URL + 'ar/transaction-get-code-combination_meaning',
  deleteArTrxDataUrl: API_BASE_URL + 'ar/delete/manual-trx',
  getValueSetDataUrl: API_BASE_URL + 'admin/value-set',
  getProvisionsUrl: API_BASE_URL + 'provision',
  getSharingRatioUrl: API_BASE_URL + 'fms/bills/sharing-ratio',
  getInterestRateUrl: API_BASE_URL + 'ar/interest-master',
  getCustomerIdUrl: API_BASE_URL + 'gl/parties/{partyId}/customer-id',
  getSummaryKeyDtlsUrl: API_BASE_URL + "admin/menu-help-id",
  getApplyReceiptUploadUrl: API_BASE_URL + 'ar/receipt'
};
export default URL_UTILITY;
