import Vue from 'vue';
import Router from 'vue-router';
// import documentsetup from './components/admin/documentsequence/documentsequence';
import Home from './components/home/home';
const dashboard = () => import('./components/landing/landing');
const notification = () => import('./components/notification/notification');
const notificationSummary = () =>
  import('./components/notification/notificationSummary');
const country = () => import('./components/admin/country');
const state = () => import('./components/admin/state');
const city = () => import('./components/admin/city');
const pincode = () => import('./components/admin/pincode');
const admin = () => import('./components/admin/admin');
const fms = () => import('./components/fms/fms');
const receipt = () => import('./components/fms/receipt');
const currency = () => import('./components/admin/currency');
const address = () => import('./components/admin/address');
const user = () => import('./components/admin/user');
const taxation = () => import('./components/taxation/taxation');
const taxgroup = () => import('./components/taxation/taxgroup');
const responsibility = () => import('./components/admin/responsibility');
const organization = () => import('./components/admin/organization');
const generalLedger = () => import('./components/generalLedger/generalLedger');
const party = () => import('./components/generalLedger/party');
const documentsequence = () => import('./components/admin/documentsequence');
const documentNumbering = () => import('./components/admin/documentNumbering');
const flexfield = () => import('./components/admin/flexfield');
const taxCategory = () => import('./components/taxation/taxCategory');
const bankModeMapping = () => import('./components/fms/bankModeMapping');
const ClearReceipt = () => import('./components/fms/receipt/clearReceipt');
const UnClearReceipt = () => import('./components/fms/receipt/unClearReceipt');
const submittedRequest = () => import('./components/request/submittedRequest');
const request = () => import('./components/request/request');
const requestGroup = () => import('./components/request/requestGroup');
const requestMaster = () => import('./components/request/requestMaster');
const template = () => import('./components/request/template');
const FmsBilling = () => import('./components/fms/transaction/billing');
const FmsTransaction = () =>
  import('./components/fms/transaction/fmsTransaction');

const role = () => import('./components/admin/role');
const Employee = () => import('./components/admin/employee');

const taxMaster = () => import('./components/taxation/taxMaster');
const taxSection = () => import('./components/taxation/taxSection');
const taxType = () => import('./components/taxation/taxType');
const taxRule = () => import('./components/taxation/taxRule');
const taxRegime = () => import('./components/taxation/taxRegime');
const LookUp = () => import('./components/admin/lookup');
const ValueSet = () => import('./components/admin/valueset');
const workFlow = () => import('./components/workFlow/workFlow');
const workFlowGroup = () => import('./components/workFlow/workFlowGroup');
const OnlineApproval = () =>
  import('./components/onlineApproval/onlineApproval');
const InternalWorkFlow = () =>
  import('./components/onlineApproval/internalWorkFlow');
const WorkFlowMasterRule = () =>
  import('./components/workFlow/workFlowMasterRule');
const WorkFlowRule = () => import('./components/workFlow/workFlowRule');
const ApplicationCriteria = () =>
  import('./components/workFlow/applicationCriteria');
const WorkFlowEvent = () => import('./components/workFlow/workflowEvent');
const WorkFlowDelegation = () =>
  import('./components/workFlow/workFlowDelegation');
const fmsMasterProject = () =>
  import('./components/fms/unitInventory/fmsMasterProject');
const fmsTower = () => import('./components/fms/unitInventory/fmsTower');
const fmsSector = () => import('./components/fms/unitInventory/fmsSector');
const fmsPocket = () => import('./components/fms/unitInventory/fmsPocket');
const fmsFloor = () => import('./components/fms/unitInventory/fmsFloor');
const fmsProject = () => import('./components/fms/unitInventory/fmsProject');
const codeCombination = () => import('./components/admin/codeCombination');
const fmsUnit = () => import('./components/fms/unitInventory/fmsUnit');
const fmsSubunit = () => import('./components/fms/unitInventory/fmsSubunit');
const updateWorkflow = () => import('./components/workFlow/updateWorkflow');
const profileOptions = () => import('./components/admin/profileOptions');
const menu = () => import('./components/admin/menu');
const oracle = () => import('./components/oracle/oracle');
const arcreatecn = () => import('./components/oracle/arcreatecn');
const billingCycle = () => import('./components/fms/masterData/billingCycle');
const projectModule = () => import('./components/project/project');
const projectMaster = () => import('./components/project/projectMaster');
const projectSector = () => import('./components/project/projectSector');
const projectPocket = () => import('./components/project/projectPocket');
const defineProjects = () => import('./components/project/defineProjects');
const projectTower = () => import('./components/project/projectTower');
const projectFloor = () => import('./components/project/projectFloor');
const projectUnit = () => import('./components/project/projectUnit');
const crmInventory = () => import('./components/crmInventory/crmInventory');
const crmMasterProj = () =>
  import('./components/crmInventory/crmMasterProject');
const crmSector = () => import('./components/crmInventory/crmSector');
const crmPocket = () => import('./components/crmInventory/crmPocket');
const crmProject = () => import('./components/crmInventory/crmProject');
const crmUnit = () => import('./components/crmInventory/crmUnit');
const crmTower = () => import('./components/crmInventory/crmTower');
const crmFloor = () => import('./components/crmInventory/crmFloor');
const Calendar = () => import('./components/admin/calendar');
const Sob = () => import('./components/admin/sob');
const OpenClosePeriod = () =>
  import('./components/generalLedger/openClosePeriod');
const wfSummary = () => import('./components/wfSummary/wfSummary');
const workFlowSummary = () => import('./components/wfSummary/summary');
const componentGroup = () =>
  import('./components/fms/masterData/componentGroup');
const componentMaster = () =>
  import('./components/fms/masterData/componentMaster');
const billingPlan = () => import('./components/fms/masterData/billingPlan');
Vue.use(Router);
const billingPlanDetails = () =>
  import('./components/fms/masterData/billingPlanDetails');
const cnSummary = () =>
  import('./components/creditNoteSummary/creditNoteSummary');
const creditNoteSummary = () =>
  import('./components/oracle/arcreatecn/searchCn');
const fmsInterestRate = () =>
  import('./components/fms/masterData/fmsInterestRate');
const menuMapping = () => import('./components/admin/menuMapping');
const fmsGenerateBatchBill = () =>
  import('./components/fms/transaction/fmsGenerateBatchBill');
const inventoryOrgLocationMpng = () =>
  import('./components/admin/inventoryLocationMapping');
const gstAdmin = () => import('./components/gstAdmin/gstAdmin');
const gstrTwoADownload = () =>
  import('./components/gstAdmin/gst/gstrTwoA/gstrTwoADownload');
const tds = () => import('./components/tds/tds');
const tdsExcelUpload = () => import('./components/tds/twoSixASUpload');
const custTdsRateMapping = () => import('./components/tds/custTdsRateMapping');
const custTanMapping = () => import('./components/tds/custTanMapping');
const twoSixASInvoices = () => import('./components/tds/twoSixASInvoices');
const lease = () => import('./components/leaseAdmin/lease/lease');
const buildingProject = () =>
  import('./components/leaseAdmin/lease/masterLease/leaseProject');
const leaseTower = () =>
  import('./components/leaseAdmin/lease/masterLease/leaseTower');
const leaseFloor = () =>
  import('./components/leaseAdmin/lease/masterLease/leaseFloor');
const securityGroup = () => import('./components/admin/securityGroup');
const leaseUnit = () =>
  import('./components/leaseAdmin/lease/masterLease/leaseUnit');
const gstCredentials = () =>
  import('./components/gstAdmin/gst/masterGst/gstCredentials');
const gstMaster = () => import('./components/gstAdmin/gst/masterGst/gstMaster');
const leaseAssignSubUnit = () =>
  import('./components/leaseAdmin/lease/masterLease/leaseAssignSubUnit');
const masterAgreement = () =>
  import('./components/leaseAdmin/lease/leases/masterAgreement');
const investorLease = () =>
  import('./components/leaseAdmin/lease/leases/investorLease');
const tenantLease = () =>
  import('./components/leaseAdmin/lease/leases/tenantLease');
const tenantSchedule = () =>
  import('./components/leaseAdmin/lease/schedules/tenantSchedule');
const investorSchedule = () =>
  import('./components/leaseAdmin/lease/schedules/investorSchedule');
const paymentAdvice = () => import('./components/oracle/paymentAdvice');
const tenantInvoices = () =>
  import('./components/leaseAdmin/lease/leaseInvoices/tenantInvoices');
const tenantManualBill = () =>
  import('./components/leaseAdmin/lease/leaseInvoices/tenantManualBill');
const investorInvoices = () =>
  import('./components/leaseAdmin/lease/leaseInvoices/investorInvoicesNew');
const tenantRecovery = () =>
  import('./components/leaseAdmin/lease/recovery/tenantRecovery');
const investorRecovery = () =>
  import('./components/leaseAdmin/lease/recovery/investorRecovery');
const tenantSecurityDeposit = () =>
  import('./components/leaseAdmin/lease/securityDeposit/tenantSecurityDeposit');
const investorSecurityDeposit = () =>
  import(
    './components/leaseAdmin/lease/securityDeposit/investorSecurityDeposit'
  );
const autoAccounting = () =>
  import('./components/fms/masterData/autoAccounting');
const itcSummaryReport = () =>
  import('./components/gstAdmin/gst/gstrTwoA/itcSummaryReport');
const itcDataUpdation = () =>
  import('./components/gstAdmin/gst/gstrTwoA/itcDataUpdation');
const tenantInvoice = () =>
  import('./components/leaseAdmin/lease/processInvoice/tenantInvoice');
const arReceivables = () => import('./components/arReceivables/arReceivables');
const arTransaction = () =>
  import('./components/arReceivables/arTransactions/transaction');
const subModule = () => import('./components/admin/subModule');
const nfaApp = () => import('./components/onlineApproval/nfaApproval');
const investorInvoice = () =>
  import('./components/leaseAdmin/lease/processInvoice/investorInvoice');
const Searchregimemapping = () =>
  import('./components/taxation/taxRegimeRegistration');
const Searchregimeaccount = () =>
  import('./components/taxation/taxRegimeAccount');
const payable = () => import('./components/payable/payable');
const invoiceEntry = () =>
  import('./components/payable/apInvoice/invoiceEntry');
const receiptac = () =>
  import('./components/arReceivables/arReceipt/receiptac');
const batchPayments = () =>
  import('./components/payable/payments/batchPayments');
const hostToHostBankTransfer = () =>
  import('./components/payable/payments/hostToHostBankTransfer');
const itcReconciliation = () =>
  import('./components/gstAdmin/gst/gstrTwoA/itcReconciliation');
const partyVerification = () =>
  import('./components/gstAdmin/gst/gstrTwoA/partyVerification');
const realManageAdmin = () =>
  import('./components/realManageAdmin/realManageAdmin');
const queryAssignment = () =>
  import('./components/realManageAdmin/queryAssignment');
const customerQuery = () =>
  import('./components/realManageAdmin/customerQueries');
const applyReceipt = () => import('./components/arReceivables/applyReceipt');
const generateInvoice = () =>
  import('./components/gstAdmin/gst/eInvoicing/generateInvoice');
const manualPayments = () =>
  import('./components/payable/payments/manualPayments');
const adviceStatus = () => import('./components/payable/payments/adviseStatus');
const apiAdmin = () => import('./components/apiAdmin/apiAdmin');
const apiRegistration = () => import('./components/apiAdmin/apiRegistration');
const invAdmin = () => import('./components/invAdmin/invAdmin');
const inventoryItems = () =>
  import('./components/invAdmin/inventory/inventoryItems');
const mutation = () => import('./components/fms/mutation');
const salesAgreement = () => import('./components/fms/salesAgreement');
const workdayCalendar = () => import('./components/fms/workdayCalender');
const fmsTimesheet = () => import('./components/fms/fmsTimesheet');
const updateTimesheet = () => import('./components/fms/updateTimesheet');
const fmsTimsheetSummary = () => import('./components/fms/fmsTimsheetSummary');
const userAccessRightsTimesheet = () => import('./components/fms/userAccessRightsTimesheet');
const maintainLots = () => import('./components/invAdmin/maintainLots');
const portalRealApp = () => import('./components/portalRealApp/portalRealApp');
const payableInvoices = () =>
  import('./components/portalRealApp/payableInvoices');
const updateParty = () => import('./components/portalRealApp/updateParty');

const changePassword = () =>
  import('./components/changePassword/changePassword');
const changePasswordForm = () =>
  import('./components/changePassword/changePasswordForm');
const assetAdmin = () => import('./components/assetAdmin/assetAdmin');
const searchAsset = () => import('./components/assetAdmin/searchAsset');
const transferAsset = () => import('./components/assetAdmin/transferAsset');
const receiveAsset = () => import('./components/assetAdmin/receiveAsset');
const createPvJob = () => import('./components/assetAdmin/createPvJob');
const faLocation = () => import('./components/assetAdmin/faLocation');
const dunningAdmin = () => import('./components/dunningAdmin/dunningAdmin');
const dunningSetup = () => import('./components/dunningAdmin/dunningSetup');
const dunningRuleMasterDetails = () =>
  import('./components/dunningAdmin/dunningRuleMasterDetails');
const dunningRuleMaster = () =>
  import('./components/dunningAdmin/dunningRuleMaster');
const customerRuleMapping = () =>
  import('./components/dunningAdmin/customerRuleMapping');
const dunningHeaderSummary = () =>
  import('./components/dunningAdmin/dunningHeaderSummary');
const rgpManagement = () => import('./components/rgpManagement/rgpManagement');
const costCenterMgmt = () =>
  import('./components/rgpManagement/costCenterMgmt');
const wareHouseMgmt = () => import('./components/rgpManagement/wareHouseMgmt');
const syncAsset = () => import('./components/assetAdmin/syncAsset');
const updateLocation = () => import('./components/assetAdmin/updateLocation');
const tagComm = () => import('./components/realManageAdmin/tagComm');
const gstrThreeBSummary = () =>
  import('./components/gstAdmin/gst/gstrTwoA/gstrThreeBSummary');
const srchactivity = () => import('./components/arReceivables/arActivity');
const gstSetup = () => import('./components/gstAdmin/gst/masterGst/gstSetup');
const arCof = () => import('./components/arReceivables/arCof');
const partyOnBoard = () => import('./components/generalLedger/partyOnboarding');
const leaseAgreement = () =>
  import('./components/generalLedger/leaseAgreement');
const complainceDashboard = () =>
  import('./components/gstAdmin/gst/complainceDashboard');
const physicalVerification = () =>
  import('./components/assetAdmin/physicalVerifiction');
const itcSupplierSummary = () =>
  import('./components/gstAdmin/gst/gstrTwoA/itcSupplierSummary');
const authRedirect = () =>
  import('./components/shared/authRedirect/authRedirect.vue');
const nearMatchForm = () =>
  import('./components/gstAdmin/gst/gstrTwoA/nearMatchForm');
const gstPerformanceMatrix = () =>
  import('./components/gstAdmin/gst/performanceMatrix');
const investorPayment = () =>
  import('./components/leaseAdmin/lease/investorPayment');
const companyCompliance = () =>
  import('./components/gstAdmin/gst/companyCompliance');
const awsBackupDashboard = () =>
  import('./components/gstAdmin/gst/awsBackupDashboard');
const deliveryChallan = () => import('./components/arReceivables/delChallan');
const SalesAgmtConsumption = () =>
  import('./components/fms/salesAgmtConsumption');
const generateEwayBill = () =>
  import('./components/assetAdmin/generateEwayBill');
const vendorEmailSetup = () =>
  import('./components/gstAdmin/gst/masterGst/vendorEmailSetup');
const locationDash = () => import('./components/assetAdmin/locationDash');
const grnAsset = () => import('./components/assetAdmin/grnAsset');
const gstRecoErrors = () =>
  import('./components/gstAdmin/gst/gstrTwoA/recoErrors');
const invoiceApproval = () =>
  import('./components/generalLedger/invoiceApproval');
import expenseReport from './components/generalLedger/expenseReport';
const gstPrIntegration = () =>
  import('./components/gstAdmin/gst/prIntegration');
const cmsAdmin = () => import('./components/cmsAdmin/cmsAdmin');
const customerBalances = () => import('./components/cmsAdmin/customerBalances');
const gstR1Summary = () =>
  import('./components/gstAdmin/gst/salesReturn/gstr1Summary');
const collectionDashboard = () =>
  import('./components/cmsAdmin/collectionDashboard');
const awsBackupReports = () => import('./components/request/awsBackupReport');
const assetItems = () => import('./components/assetAdmin/assetMasterItems');
const assetTags = () => import('./components/assetAdmin/assetMasterTags');
const assetLocators = () =>
  import('./components/assetAdmin/assetMasterLocators');
const salesRegister = () =>
  import('./components/gstAdmin/gst/salesReturn/salesRegister');
const panBankVerification = () =>
  import('./components/generalLedger/panBankVerification');
const assetAllocation = () => import('./components/assetAdmin/assetAllocation');
const locatorCodeCombination = () =>
  import('./components/assetAdmin/locatorCodeCombination');
  const assetAudit =()=> import('./components/assetAdmin/assetAudit');
  const fusionAdmin = () => import('./components/fusionAdmin/fusionAdmin');
  const arInvoicePrint = () =>
  import('./components/fusionAdmin/arInvoicePrint');
  const sqlConnect = () =>
  import('./components/fusionAdmin/sqlConnect');
  const gstnStatus = () => import("./components/gstAdmin/gst/gstnStatus");
  const vendorHoldMapping = () => import('./components/gstAdmin/gst/masterGst/vendorHoldMapping');
  const source = () => import('./components/admin/source')
  const recieptUpload = () => import('./components/arReceivables/recieptUpload');
  const emailler = () => import ('./components/gstAdmin/gst/emailler');
  const rfp = () => import('./components/onlineApproval/rfp');
  const costSheetTermCondition = () => import ('./components/fms/costSheetTermCondition');
  const provAdmin =() =>import('./components/provisionsAdmin/provisionsAdmin')
  const uploadProv = ()=> import('./components/provisionsAdmin/uploadProvisions')
  const codAdmin = () => import('./components/codAdmin/codAdmin');
  const codReco = () => import('./components/codAdmin/codReco');
  const unitSubunit = () => import('./components/fms/masterData/unitSubunit');
  const billUpload = () => import('./components/fms/transaction/billUpload');
  const eWayBill = () =>import('./components/gstAdmin/gst/eWayBill');
  const interestRate = () => import('./components/arReceivables/interestRate');
  const vendorHoldRelease = () => import('./components/gstAdmin/gst/vendorHoldRelease');
let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/oauth2/redirect',
      name: 'authRedirect',
      component: authRedirect
    },
    {
      path: '/workflow',
      name: 'workflow',
      component: wfSummary,
      children: [
        {
          path: ':wfType/summary/:summaryData',
          name: 'workFlowSummary',
          component: workFlowSummary
        }
      ]
    },
    {
      path: '/cn',
      name: 'cn',
      component: cnSummary,
      children: [
        {
          path: 'summary/:summaryData',
          name: 'cnsummary',
          component: creditNoteSummary
        }
      ]
    },
    {
      path: '/landing',
      name: 'landing',
      // lazy-loaded
      component: () => import('./components/landing/landing'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: dashboard,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'admin',
          name: 'admin',
          component: admin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'calendar',
              name: 'calendarHeaderDetail',
              component: Calendar
            },
            {
              path: 'menu-mapping',
              name: 'menu-mapping',
              component: menuMapping
            },
            {
              path: 'sob',
              name: 'searchSOB',
              component: Sob
            },
            {
              path: 'address',
              name: 'address',
              component: address
            },
            {
              path: 'country',
              name: 'defineCountry',
              component: country
            },
            {
              path: 'state',
              name: 'defineState',
              component: state
            },
            {
              path: 'city',
              name: 'defineCity',
              component: city
            },
            {
              path: 'pincode',
              name: 'definePinCode',
              component: pincode
            },
            {
              path: 'currency',
              name: 'defineCurrency',
              component: currency
            },
            {
              path: 'user',
              name: 'searchUser',
              component: user
            },
            // {
            //   path: 'arcreatecn',
            //   name: 'arcreatecn',
            //   component: arcreatecn
            // },
            {
              path: 'responsibility',
              name: 'defineResponsibility',
              component: responsibility
            },
            {
              path: 'flexfield',
              name: 'segmentDetails',
              component: flexfield
            },
            {
              path: 'role',
              name: 'addRole',
              component: role
            },

            {
              path: 'organization',
              name: 'Organization',
              component: organization
            },
            {
              path: 'documentsequence',
              name: 'getDocSequencing',
              component: documentsequence
            },
            {
              path: 'documentNumbering',
              name: 'getDocNumbring',
              component: documentNumbering
            },
            {
              path: 'hrms/employee',
              name: 'addEmp',
              component: Employee
            },
            {
              path: 'lookup',
              name: 'lookup',
              component: LookUp
            },
            {
              path: 'valueset',
              name: 'searchValueSet',
              component: ValueSet
            },
            {
              path: 'code-combination',
              name: 'codeCombSearch',
              component: codeCombination
            },
            {
              path: 'profile-option',
              name: 'defineProfileOptions',
              component: profileOptions
            },
            {
              path: 'menu',
              name: 'menu',
              component: menu
            },
            {
              path: 'menu-mapping',
              name: 'srchMenuMPNG',
              component: menuMapping
            },
            {
              path: 'inventory-org-location-mapping',
              name: 'srchInvOrgmpng',
              component: inventoryOrgLocationMpng
            },
            {
              path: 'security-group',
              name: 'secGrp',
              component: securityGroup
            },
            {
              path: 'sub-module',
              name: 'srchsubmodule',
              component: subModule
            },
            {
              path: 'source',
              name: 'adminSource',
              component: source
            }
          ]
        },
        {
          path: 'project',
          name: 'project',
          component: projectModule,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'project-master',
              name: 'prjMasterSearch',
              component: projectMaster
            },
            {
              path: 'project-sector',
              name: 'projectSectorDetail',
              component: projectSector
            },
            {
              path: 'project-pocket',
              name: 'projectPocketDetail',
              component: projectPocket
            },
            {
              path: 'define-project',
              name: 'projectMasterDetail',
              component: defineProjects
            },
            {
              path: 'project-tower',
              name: 'projectTowerDetail',
              component: projectTower
            },
            {
              path: 'project-floor',
              name: 'projectFloorDetail',
              component: projectFloor
            },
            {
              path: 'project-unit',
              name: 'unitMasterDetail',
              component: projectUnit
            }
          ]
        },
        {
          path: 'cmsAdmin',
          name: 'cmsAdmin',
          component: cmsAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'customerBalances',
              name: 'srchcustomerbal',
              component: customerBalances
            },
            {
              path: 'collectionDashboard',
              name: 'collectionDashboard',
              component: collectionDashboard
            }
          ]
        },
        {
          path: 'codAdmin',
          name: 'codAdmin',
          component: codAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'codReco',
              name: 'codreco',
              component: codReco
            }
          ]
        },
        {
          path: 'fusionAdmin',
          name: 'fusionAdmin',
          component: fusionAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'arInvPrint',
              name: 'arInvPrint',
              component: arInvoicePrint
            },
            {
              path: 'sqlConnect',
              name: 'sql-connect',
              component: sqlConnect
            },
          ]
        },
        {
          path: 'crmInventory',
          name: 'crmInventory',
          component: crmInventory,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'crm-master-project',
              name: 'crmMasterProjectDetail',
              component: crmMasterProj
            },
            {
              path: 'crm-sector',
              name: 'crmSectorDetail',
              component: crmSector
            },
            {
              path: 'crm-pocket',
              name: 'crmPocketDetail',
              component: crmPocket
            },
            {
              path: 'crm-project',
              name: 'crmProjectDetail',
              component: crmProject
            },
            {
              path: 'crm-unit',
              name: 'crmUnitDetail',
              component: crmUnit
            },
            {
              path: 'crm-tower',
              name: 'crmTowerDetail',
              component: crmTower
            },
            {
              path: 'crm-floor',
              name: 'crmFloorDetail',
              component: crmFloor
            }
          ]
        },
        {
          path: 'fms',
          name: 'fms',
          component: fms,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'searchFMSReceipt',
              path: 'create-receipt',
              component: receipt
            },
            {
              name: 'searchBankModeMpng',
              path: 'bankModeMapping',
              component: bankModeMapping
            },
            {
              name: 'searchFMSClearReceipt',
              path: 'clear-receipt',
              component: ClearReceipt
            },
            {
              name: 'searchFMSUnClearReceipt',
              path: 'unclear-receipt',
              component: UnClearReceipt
            },
            {
              name: 'searchGenerateBill',
              path: 'billing',
              component: FmsBilling
            },
            {
              name: 'searchFMSTrx',
              path: 'transaction',
              component: FmsTransaction
            },
            {
              name: 'srchFMSGenerateBatchBill',
              path: 'transaction/fms-generate-batch-bill',
              component: fmsGenerateBatchBill
            },
            {
              name: 'fmsMasterProject',
              path: 'master-project',
              component: fmsMasterProject
            },
            {
              name: 'fmsTower',
              path: 'tower',
              component: fmsTower
            },
            {
              name: 'fmsSector',
              path: 'sector',
              component: fmsSector
            },
            {
              name: 'fmsPocket',
              path: 'pocket',
              component: fmsPocket
            },
            {
              name: 'fmsFloor',
              path: 'floor',
              component: fmsFloor
            },
            {
              name: 'fmsProjectView',
              path: 'projects',
              component: fmsProject
            },
            {
              name: 'fmsUnitView',
              path: 'unit',
              component: fmsUnit
            },
            {
              name: 'fmsSubUnitSearch',
              path: 'subunit',
              component: fmsSubunit
            },
            {
              name: 'searchBillingCycle',
              path: 'master-data/billing-cycle',
              component: billingCycle
            },
            {
              name: 'searchCompGrp',
              path: 'master-data/component-group',
              component: componentGroup
            },
            {
              name: 'searchComp',
              path: 'master-data/component-master',
              component: componentMaster
            },
            {
              name: 'searchBillingPlan',
              path: 'master-data/billing-plan',
              component: billingPlan
            },
            {
              name: 'billingPlanDetails',
              path: 'master-data/billing-plan-detail/:billingPlanId',
              component: billingPlanDetails
            },
            {
              name: 'searchFMSInterestRate',
              path: 'master-data/fmsInterestRate',
              component: fmsInterestRate
            },
            {
              name: 'srchauac',
              path: 'master-data/auto-accounting',
              component: autoAccounting
            },
            {
              name: 'searchFmsMutation',
              path: 'mutation',
              component: mutation
            },
            {
              name: 'salesAgreement',
              path: 'sales-agreement',
              component: salesAgreement
            },
            {
              name: 'workdayCalendar',
              path: 'workday-calendar',
              component: workdayCalendar
            },
            {
              name: 'fmsTimesheet',
              path: 'fms-timesheet',
              component: fmsTimesheet
            },
            {
              name: 'salesAgmtConsumption',
              path: 'sales-agmt-consumption',
              component: SalesAgmtConsumption
            },
            {
              name: 'updateTimesheet',
              path: 'fms-update-timesheet',
              component: updateTimesheet
            },
            {
              name: 'timesheetUAR',
              path: 'fms-timesheet-user-access-rights',
              component: userAccessRightsTimesheet
            },
            {
              name: 'fmsTimesheetSummary',
              path: 'fms-timesheet-summary',
              component: fmsTimsheetSummary
            },
            {
              name: 'costTermCond',
              path: 'cost-sheet-term-condtion',
              component: costSheetTermCondition
            },
            {
              name: 'unitSubunit',
              path: 'unit-subunit',
              component: unitSubunit
            },
            {
              name: 'billUpload',
              path: 'fms-bill-upload',
              component: billUpload
            }
          ]
        },
        {
          path: 'taxation',
          name: 'taxation',
          component: taxation,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'taxGroup',
              path: 'taxgroup',
              component: taxgroup
            },
            {
              name: 'definetaxCategory',
              path: 'taxCategory',
              component: taxCategory
            },
            {
              name: 'searchTaxMaster',
              path: 'taxMaster',
              component: taxMaster
            },
            {
              name: 'SearchtaxSection',
              path: 'taxSection',
              component: taxSection
            },
            {
              name: 'searchtaxType',
              path: 'taxType',
              component: taxType
            },
            {
              name: 'searchTaxRule',
              path: 'taxRule',
              component: taxRule
            },
            {
              name: 'defineTaxRegime',
              path: 'taxRegime',
              component: taxRegime
            },
            {
              name: 'Searchregimemapping',
              path: 'taxRegime-registration',
              component: Searchregimemapping
            },
            {
              name: 'Searchregimeaccount',
              path: 'taxRegime-account',
              component: Searchregimeaccount
            }
          ]
        },
        {
          path: 'general-ledger',
          name: 'generalLedger',
          component: generalLedger,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'party',
              name: 'searchParty',
              component: party
            },
            {
              path: 'open-close-period',
              name: 'periodDetailSearch',
              component: OpenClosePeriod
            },
            {
              path: 'party-onboarding',
              name: 'partyOnBoard',
              component: partyOnBoard
            },
            {
              path: 'lease-agreement',
              name: 'leaseAgreement',
              component: leaseAgreement
            },
            {
              path: 'expense-report',
              name: 'expense_gl_acc',
              component: expenseReport
            },
            {
              path: 'invoice-approval',
              name: 'invoiceApproval',
              component: invoiceApproval
            },
            {
              path: 'pan-bank-verification',
              name: 'panBankVerification',
              component: panBankVerification
            }
          ]
        },
        {
          path: 'request',
          name: 'request',
          component: request,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'request-group',
              name: 'createRequestGroup',
              component: requestGroup
            },
            {
              path: 'request-master',
              name: 'searchRequest',
              component: requestMaster
            },
            {
              path: 'template',
              name: 'searchRequestTemplate',
              component: template
            },
            {
              name: 'searchSubmittedRequest',
              path: 'submittedRequest/:request_id?',
              component: submittedRequest
            },
            {
              name: 'aws_backup_reports',
              path: 'awsBackupReports',
              component: awsBackupReports
            }
          ]
        },
        {
          path: 'work-flow',
          name: 'workFLow',
          component: workFlow,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'group',
              name: 'initWFGroup',
              component: workFlowGroup
            },
            {
              path: 'master-rule',
              name: 'initWFMstrRule',
              component: WorkFlowMasterRule
            },
            {
              path: 'rule',
              name: 'initWFRule',
              component: WorkFlowRule
            },
            {
              path: 'app-criteria',
              name: 'searchWFCriteria',
              component: ApplicationCriteria
            },
            {
              path: 'event',
              name: 'wFEvent',
              component: WorkFlowEvent
            },
            {
              path: 'delegation',
              name: 'searchWFDelegation',
              component: WorkFlowDelegation
            },
            {
              path: 'update-workflow',
              name: 'initWFSysAdmin',
              component: updateWorkflow
            }
          ]
        },
        {
          path: 'online-approval',
          name: 'onlineApproval',
          component: OnlineApproval,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'internal-workflow',
              name: 'initNFA',
              component: InternalWorkFlow
            },
            {
              path: 'nfa-approval',
              name: 'nfaApp',
              component: nfaApp
            },
            {
              path: 'rfp',
              name: 'rfp',
              component: rfp
            }
          ]
        },
        {
          path: 'notification',
          name: 'notification',
          component: notification,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'notificationSummary',
              path: 'notificationSummary',
              component: notificationSummary
            }
          ]
        },
        {
          path: 'changePassword',
          name: 'changePassword',
          component: changePassword,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'changePasswordForm',
              path: 'changePasswordForm',
              component: changePasswordForm
            }
          ]
        },
        {
          path: 'oracle',
          name: 'oracle',
          component: oracle,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'initARCN',
              path: 'arcreatecn',
              component: arcreatecn
            },
            {
              name: 'paymentadvice',
              path: 'payment-advice',
              component: paymentAdvice
            }
          ]
        },
        {
          path: 'gstAdmin',
          name: 'gstAdmin',
          component: gstAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'gstr2adwnl',
              path: 'gstrTwoADownload',
              component: gstrTwoADownload
            },
            {
              name: 'gstcredientials',
              path: 'gstCredentials',
              component: gstCredentials
            },
            {
              name: 'gstmaster',
              path: 'gstMaster',
              component: gstMaster
            },
            {
              name: 'gstItcSummaryRpt',
              path: 'itcSummaryReport',
              component: itcSummaryReport
            },
            {
              name: 'srchitcdataupdation',
              path: 'itcDataUpdation',
              component: itcDataUpdation
            },
            {
              name: 'srchItcRec',
              path: 'itcReconciliation',
              component: itcReconciliation
            },
            {
              name: 'SrchPartyVerification',
              path: 'partyVerification',
              component: partyVerification
            },
            {
              name: 'SrchGnrtInvoice',
              path: 'generateInvoice',
              component: generateInvoice
            },
            {
              name: 'gstr3bSummary',
              path: 'gstr3b-summary',
              component: gstrThreeBSummary
            },
            {
              name: 'gstsetup',
              path: 'gstSetup',
              component: gstSetup
            },
            {
              name: 'gstItcSupplierSummaryRpt',
              path: 'gstItcSupplierSummaryRpt',
              component: itcSupplierSummary
            },
            {
              name: 'compDash',
              path: 'comp-dash',
              component: complainceDashboard
            },
            {
              name: 'getNearMatchForm',
              path: 'near-Match-Form',
              component: nearMatchForm
            },
            {
              name: 'performanceMatrix',
              path: 'performanceMatrix',
              component: gstPerformanceMatrix
            },
            {
              name: 'company_compliance',
              path: 'company-compliance',
              component: companyCompliance
            },
            {
              name: 'awsBackup',
              path: 'aws-backup-dashboard',
              component: awsBackupDashboard
            },
            {
              name: 'vendorEmailSetup',
              path: 'vendor-email-setup',
              component: vendorEmailSetup
            },
            {
              name: 'reco_errors',
              path: 'reco-errors',
              component: gstRecoErrors
            },
            {
              name: 'pr_integration',
              path: 'pr-integration',
              component: gstPrIntegration
            },
            {
              name: 'gst_r1_summary',
              path: 'gstr1-summary',
              component: gstR1Summary
            },
            {
              name: 'salesRegsiter',
              path: 'sales-register',
              component: salesRegister
            },
            {
              name: 'gstn_status',
              path: 'gstn_status',
              component: gstnStatus
            },
            {
              name: 'vendorHoldMapping',
              path: 'vendor-hold-mapping',
              component: vendorHoldMapping
            },
            {
              name: 'srchEmail',
              path: 'emailler',
              component: emailler
            },
            {
              name: 'eWayBill',
              path: 'eWayBill',
              component: eWayBill
            },
            {
              name: 'srchVendorHoldRelease',
              path: 'vendorHoldRelease',
              component: vendorHoldRelease
            }
          ]
        },
        {
          path: 'apiAdmin',
          name: 'apiAdmin',
          component: apiAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'apiregistration',
              path: 'apiRegistration',
              component: apiRegistration
            }
          ]
        },
        {
          path: 'invAdmin',
          name: 'invAdmin',
          component: invAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'invItems',
              path: 'inventoryItems',
              component: inventoryItems
            },
            {
              name: 'maintainInvLots',
              path: 'maintain-lots',
              component: maintainLots
            }
          ]
        },
        {
          path: 'tds',
          name: 'tds',
          component: tds,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'srch26AS',
              path: 'twoSixasupload',
              component: tdsExcelUpload
            },
            {
              name: 'srchcusttdsratesmpng',
              path: 'custTdsRateMapping',
              component: custTdsRateMapping
            },
            {
              name: 'tanmpng',
              path: 'custTanMapping',
              component: custTanMapping
            },
            {
              name: 'tds26ASInvoices',
              path: 'tds-AS-Invoices',
              component: twoSixASInvoices
            }
          ]
        },
        {
          path: 'lease',
          name: 'lease',
          component: lease,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'srchBuilding',
              path: 'building-project',
              component: buildingProject
            },
            {
              name: 'srchtower',
              path: 'lease-tower',
              component: leaseTower
            },
            {
              name: 'srchfloor',
              path: 'lease-floor',
              component: leaseFloor
            },
            {
              name: 'srchUnit',
              path: 'leaseUnit',
              component: leaseUnit
            },
            {
              name: 'srchsubunit',
              path: 'assign-sub-unit',
              component: leaseAssignSubUnit
            },
            {
              name: 'masterAgreement',
              path: 'leases/master-agreement',
              component: masterAgreement
            },
            {
              name: 'tenantLease',
              path: 'leases/tenant-lease',
              component: tenantLease
            },
            {
              name: 'investorLease',
              path: 'leases/investor-lease',
              component: investorLease
            },
            {
              name: 'srchtntsch',
              path: 'schedule/tenant-schedule',
              component: tenantSchedule
            },
            {
              name: 'srchinvsch',
              path: 'schedule/investor-schedule',
              component: investorSchedule
            },
            {
              name: 'scrhtntrent',
              path: 'invoices/tenant-invoices',
              component: tenantInvoices
            },
            {
              name: 'srchinvrent',
              path: 'invoices/investor-invoices',
              component: investorInvoices
            },
            {
              name: 'srchtntrecv',
              path: 'recovery/tenant-recovery',
              component: tenantRecovery
            },
            {
              name: 'srchinvrecv',
              path: 'recovery/investor-recovery',
              component: investorRecovery
            },
            {
              name: 'tenantSecurityDeposit',
              path: 'security-deposit/tenant-sd',
              component: tenantSecurityDeposit
            },
            {
              name: 'investorSecurityDeposit',
              path: 'security-deposit/investor-sd',
              component: investorSecurityDeposit
            },
            {
              name: 'srchTenantInvoice',
              path: 'process-invoice/tenant',
              component: tenantInvoice
            },
            {
              name: 'srchInvestorInvoice',
              path: 'process-invoice/investor',
              component: investorInvoice
            },
            {
              name: 'srchInvPayDash',
              path: 'investor-payment',
              component: investorPayment
            },
            {
              name: 'tenantManualBill',
              path: 'invoices/tenant-manual-bill',
              component: tenantManualBill
            }
          ]
        },
        {
          path: 'arReceivables',
          name: 'arReceivables',
          component: arReceivables,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'srchartrx',
              path: 'arTransactions/transaction',
              component: arTransaction
            },
            {
              name: 'srchapplyrcpttrx',
              path: 'applyReceipt',
              component: applyReceipt
            },
            {
              name: 'srcharreceiptac',
              path: 'arReceipt/receiptac',
              component: receiptac
            },
            {
              name: 'srchactivity',
              path: 'activity',
              component: srchactivity
            },
            {
              name: 'receivablesCof',
              path: 'ar-cof',
              component: arCof
            },
            {
              name: 'delChallan',
              path: 'delChallan',
              component: deliveryChallan
            },
            {
              name: 'receiptUpload',
              path: 'recieptUpload',
              component: recieptUpload
            },
            {
              name: 'intRate',
              path: 'interestRate',
              component: interestRate
            }
          ]
        },
        {
          path: 'payable',
          name: 'payable',
          component: payable,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'srchInventry',
              path: 'ap-invoice/invoice-entry',
              component: invoiceEntry
            },
            {
              name: 'srchbatchpymts',
              path: 'payments/batch-payments',
              component: batchPayments
            },
            {
              name: 'h2hBnkTrnsfr',
              path: 'payments/host-to-host-bank-transfer',
              component: hostToHostBankTransfer
            },
            {
              name: 'srchmanualpayment',
              path: 'manualPayments',
              component: manualPayments
            },
            {
              name: 'advstatus',
              path: 'adviceStatus',
              component: adviceStatus
            }
          ]
        },
        {
          name: 'realManageAdmin',
          path: 'real-manage-admin',
          component: realManageAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'srchcustquery',
              path: 'customer-query',
              component: customerQuery
            },
            {
              name: 'ccQueryAssignment',
              path: 'query-assignment',
              component: queryAssignment
            },
            {
              name: 'fmsTagEmail',
              path: 'tag-communication',
              component: tagComm
            }
          ]
        },
        {
          path: 'portal-realapp',
          name: 'portalRealApp',
          component: portalRealApp,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'srchpayableinvoices',
              path: 'payable-invoices',
              component: payableInvoices
            },
            {
              name: 'srchupdateparty',
              path: 'update-party',
              component: updateParty
            }
          ]
        },
        {
          path: 'assetAdmin',
          name: 'assetAdmin',
          component: assetAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'searchAsset',
              path: 'search-asset',
              component: searchAsset
            },
            {
              name: 'transferAsset',
              path: 'transfer-asset',
              component: transferAsset
            },
            {
              name: 'receiveAsset',
              path: 'receive-asset',
              component: receiveAsset
            },
            {
              name: 'syncAsset',
              path: 'sync-asset',
              component: syncAsset
            },
            {
              name: 'updateLocation',
              path: 'update-location',
              component: updateLocation
            },
            {
              name: 'createPvJob',
              path: 'create-pv-job',
              component: createPvJob
            },
            {
              name: 'physicalVerification',
              path: 'physical-Verification',
              component: physicalVerification
            },
            {
              name: 'faLoc',
              path: 'faLoc',
              component: faLocation
            },
            {
              name: 'genEwayBill',
              path: 'generate-Eway-bill',
              component: generateEwayBill
            },
            {
              name: 'LocDash',
              path: 'LocDash',
              component: locationDash
            },
            {
              name: 'grnAsset',
              path: 'grnAsset',
              component: grnAsset
            },
            {
              name: 'assetMasterItems',
              path: 'assetMasterItems',
              component: assetItems
            },
            {
              name: 'assetMasterTags',
              path: 'assetMasterTags',
              component: assetTags
            },
            {
              name: 'assetMasterLocators',
              path: 'assetMasterLocators',
              component: assetLocators
            },
            {
              name: 'assetAllocation',
              path: 'asset-allocation',
              component: assetAllocation
            },
            {
              name: 'locCodeComb',
              path: 'loc-code-comb',
              component: locatorCodeCombination
            },
            {
              name: 'audit',
              path: 'audit',
              component: assetAudit
            }
          ]
        },
        {
          path: 'dunningAdmin',
          name: 'dunningAdmin',
          component: dunningAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'dunningsetup',
              path: 'dunning-setup',
              component: dunningSetup
            },
            {
              name: 'ruledetail',
              path: 'dunning-rule-master-details',
              component: dunningRuleMasterDetails
            },
            {
              name: 'rulemaster',
              path: 'dunning-rule-master',
              component: dunningRuleMaster
            },
            {
              name: 'rulemapping',
              path: 'customer-rule-mapping',
              component: customerRuleMapping
            },
            {
              name: 'dunningheadersummary',
              path: 'dunning-header-summary',
              component: dunningHeaderSummary
            }
          ]
        },
        {
          path: 'rgp-management',
          name: 'rgpManagement',
          component: rgpManagement,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'rgpCcMgmt',
              path: 'cost-center-mgmt',
              component: costCenterMgmt
            },
            {
              name: 'rgpWhMgmt',
              path: 'ware-house-mgmt',
              component: wareHouseMgmt
            }
          ]
        },
        {
          path: 'provisionsAdmin',
          name: 'provisionsAdmin',
          component: provAdmin,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              name: 'uploadProvisions',
              path: 'uploadProvisions',
              component: uploadProv
            }
          ]
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (sessionStorage.token) {
      next();
      return;
    }
    next('/');
  } else {
    next();
  }
});

export default router;
