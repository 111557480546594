import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getOrganizationLov = (context, payload) => {
  const url = URL_UTILITY.getOrgannizationLovUrl.replace('{lovType}', 'LE');
  const response = http.getApi(url + '?responsibility_id=' + payload);
  return response;
};

const getLOVBySetType = (context, setType) => {
  const url = URL_UTILITY.getLOVBySetTypeUrl.replace('{valueSetType}', setType);
  const response = http.getApi(url);
  return response;
};

const getOtherLovByLovFieldAndId = (contect, lovFieldAndId) => {
  const url = URL_UTILITY.getOtherLovByProjectIdUrl
    .replace('{lovField}', lovFieldAndId.lovFieldName)
    .replace('{id}', lovFieldAndId.lovId);
  const response = http.getApi(url);
  return response;
};

const getOptionProfileByProfileType = (context, profileType) => {
  const url = URL_UTILITY.getOptionProfileUrl.replace(
    '{profileType}',
    profileType
  );
  const response = http.getApi(url);
  return response;
};

const fmsFilter = (context, payload) => {
  const url = URL_UTILITY.getReceiptFilterUrl;
  const response = http.postApi(
    `${url}?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`,
    payload.searchParams
  );
  return response;
};

const getFMSReceiptById = (context, receiptId) => {
  const url = URL_UTILITY.getFMSReceiptById.replace(
    '{receiptHdrId}',
    receiptId
  );
  const response = http.getApi(url);
  return response;
};
const getCustomerDetail = (context, payload) => {
  const url = URL_UTILITY.getCustomerDetailUrl;
  return http.getApi(url, payload);
};
const getReceiptMethods = (context, payload) => {
  const url = URL_UTILITY.getReceiptMethodsUrl;
  return http.getApi(url, payload);
};
const getOutStandinglist = (context, payload) => {
  const url = URL_UTILITY.getReceiptOutstandingListUrl;
  return http.getApi(url, payload);
};
const getTaxCatory = () => {
  const url = URL_UTILITY.getTaxCategoryUrl;
  return http.getApi(url);
};

const addEditReceipt = (context, payload) => {
  const url = URL_UTILITY.addEditReceiptUrl;
  return http.putApi(url, payload);
};

const getReceiptAdvanceData = (context, payload) => {
  const url = URL_UTILITY.getReceiptAdvanceDataUrl;
  return http.getApi(url, payload);
};
const clearReceipt = (context, payload) => {
  const url = URL_UTILITY.getReceiptClearUrl;
  return http.postApi(url, payload);
};
const getReceiptAccounting = (context, payload) => {
  const url = URL_UTILITY.getReceiptAccountingUrl;
  return http.getApi(url, payload);
};
const getReceiptUnClear = (context, payload) => {
  const url = URL_UTILITY.getReceiptUnClearUrl;
  return http.postApi(url, payload);
};
const getProjectList = (context, payload) => {
  const url = URL_UTILITY.getProjectListUrl.replace(
    '{typeOfProject}',
    payload.typeOfProject
  );
  return http.getApi(url + '?le_id=' + payload.le_id);
};
const getSource = () => {
  return http.getApi(URL_UTILITY.getSourceUrl);
};
const getBillingCycle = () => {
  return http.getApi(URL_UTILITY.getBillCycleUrl);
};
const getFmsBills = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsBillsUrl +
      '?_page=' +
      payload._page +
      '&_limit=' +
      payload._limit,
    payload
  );
  // const url = URL_UTILITY.getFmsBillsUrl;
  // return http.postApi(
  //   `${url}?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`,
  //   payload
  // );
};
const getFmsBillDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBillsUrl + '/' + payload.bill_hdr_id,
    payload.queryParams
  );
};
const getOvertimeDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBillsUrl + '/' + payload.bill_hdr_id + '/overtime',
    payload.queryParams
  );
};
const getOvertimeSummaryDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBillsUrl + '/' + payload.bill_hdr_id + '/summary-overtime',
    payload.queryParams
  );
};
const addEditBillDetails = (context, payload) => {
  return http.postApi(URL_UTILITY.addEditBillDetailsUrl, payload);
};
const saveActualRateForProvisional = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsAdminUrl + `/actual-rate`, payload.tempbillPayload);
};
const getFmsBillRequest = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsBillRequestUrl, payload);
};
const getFMSBillingTemplate = (context, payload) => {
  return http.getApi(URL_UTILITY.getFMSBillTemplateUrl, payload);
};
const getFMSBillingDetail = (context, billHdrId) => {
  const url = URL_UTILITY.getFMSBillDetailsUrl.replace(
    '{billHdrId}',
    billHdrId
  );
  return http.getApi(url);
};
const getFMSBillOutStanding = (context, payload) => {
  const url = URL_UTILITY.getFMSBillOutStandingUrl;
  return http.getApi(url, payload);
};
const getFMSDistribution = (context, payload) => {
  return http.getApi(URL_UTILITY.getFMSBillDistributionUrl, payload);
};
const getFMSTransaction = (context, payload) => {
  const url = URL_UTILITY.getFMSTransactionListUrl;
  return http.postApi(
    `${url}?_page=${payload.pagination._page}&_limit=${payload.pagination._limit}`,
    payload
  );
};

const getFMSTransactionLineDetails = (context, trxHdrId) => {
  const url = URL_UTILITY.getFMSTransactionListUrl;
  return http.getApi(url + '/' + trxHdrId);
};
const getFMSTransactionLineDataByDtlId = (context, trxDtlId) => {
  const url = URL_UTILITY.getFMSTransactionLineDetailsUrl.replace(
    '{trxDtlId}',
    trxDtlId
  );
  return http.getApi(url);
};
const getTaxDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getTaxDetailsByIdUrl +
      '/' +
      payload.bill_hdr_id +
      '/' +
      payload.module_name +
      '?trx_line_id=' +
      payload.trx_line_id
  );
};
const getProvisionalTaxDetailsById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getTaxDetailsByIdUrl +
      '-temp/' +
      payload.bill_hdr_id +
      '/' +
      payload.module_name +
      '?trx_line_id=' +
      payload.trx_line_id
  );
};
const getEinvoiceDetailsById = (context, payload) => {
  return http.getApi(URL_UTILITY.getEinvoiceDetailsByIdUrl, payload);
};
const downloadBillingCompDtl = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsAdminUrl + '/download-excel',
    payload
  );
};
const getFmsTimesheetDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBillsUrl + '/timesheet/details',
    payload
  );
};
const deleteTempBill = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsAdminUrl +
      '/delete-tmp-bill/' +
      payload.billhdrtmpId +
      '/tmpbill'
  );
};
const editFinalBillDate = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsAdminUrl +
      '/final-bill-edit-billdate/' +
      payload.bill_hdr_id,
    payload
  );
};
const getFmsTimesheetSummary = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBillsUrl + '/timesheet/details-hdr',
    payload
  );
};
const getFmsSalesAgmtConsumptionDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBillsUrl + '/sales-cons-dtls',
    payload
  );
};
const getSharingRatio = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getSharingRatioUrl + '/' + payload.customer_id + '/' + payload.unit_id
  );
};
export default {
  getOrganizationLov,
  getLOVBySetType,
  getProjectList,
  getOtherLovByLovFieldAndId,
  getOptionProfileByProfileType,
  fmsFilter,
  getFMSReceiptById,
  getCustomerDetail,
  getReceiptMethods,
  getOutStandinglist,
  getTaxCatory,
  addEditReceipt,
  getReceiptAdvanceData,
  clearReceipt,
  getReceiptAccounting,
  getReceiptUnClear,
  getSource,
  getBillingCycle,
  getFmsBills,
  getFmsBillRequest,
  getFMSBillingTemplate,
  getFMSBillingDetail,
  getFMSBillOutStanding,
  getFMSDistribution,
  getFMSTransaction,
  getFMSTransactionLineDetails,
  getFMSTransactionLineDataByDtlId,
  getFmsBillDetailsById,
  addEditBillDetails,
  getTaxDetailsById,
  getEinvoiceDetailsById,
  getFmsTimesheetDetails,
  saveActualRateForProvisional,
  getProvisionalTaxDetailsById,
  deleteTempBill,
  editFinalBillDate,
  getFmsTimesheetSummary,
  downloadBillingCompDtl,
  getFmsSalesAgmtConsumptionDetails,
  getOvertimeDetailsById,
  getOvertimeSummaryDetailsById,
  getSharingRatio
};
