import { BasicSelect, ModelSelect } from 'vue-search-select';
import RuntimeModal from '../runtimeModal';
import templateModule from '../templateModule';
import store from '../../../../../store';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import { format } from 'date-fns';
import DatePicker from 'vue2-datepicker';
import dependentValueSetModal from '../../../shared/dependentValueSetModal';

export default {
  name: 'RunRequest',
  props: ['reqRun', 'reqRunNearMatch', 'retryDtls', 'reRunReq'],
  components: {
    ModelSelect,
    RuntimeModal,
    templateModule,
    DatePicker,
    dependentValueSetModal,
    BasicSelect
  },
  watch: {
    'form.name.text': function() {
      this.form.template.value = null;
      this.form.template.text = null;
      this.name = this.form.name.text;
      this.template = null;
      if (this.form.name.text !== null) {
        this.getRequestBasedFlags(this.form.name.text);
        this.getTemplate();
      }
    },
    'form.template.value': function() {
      this.data = [
        {
          display_id: null,
          data_input: null,
          data_input_id: null,
          parameter_name: null
        }
      ];
      if (this.form.template.value) {
        this.getTemplateList(this.form.template.value);
        this.getParameterMapping(this.form.template.value);
      }
    },
    secondaryDate: function() {
      const dateComparison = this.primaryDate < this.secondaryDate;
      if (!dateComparison) {
        this.showAlert = true;
        this.responseMsg =
          this.primaryDateId +
          ' cannot be greater than ' +
          this.secondaryDateId;
      } else {
        this.showAlert = false;
        this.responseMsg = '';
      }
    }
  },
  data() {
    return {
      gstFlag: false,
      mandatoryParamObj: {},
      paramRequestId: null,
      respTableIndex: 0,
      showRunRequestModal: false,
      showValuesetForm: false,
      showTemplateForm: false,
      showRuntimeModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      requestArr: [],
      requestInfoList: [],
      requestFormatArr: [],
      maxlength: 0,
      argumentLength: 0,
      setTimeRunStatus: null,
      setTimeDependentTemp: null,
      setTimeVsetCode: null,
      primaryDate: null,
      secondaryDate: null,
      primaryDateId: null,
      secondaryDateId: null,
      dependentId: null,
      valuesetVariable: [],
      valuesetVariableId: [],
      commTempName: null,
      isGroupByValid: true,
      valueSetTypeLookup: null,
      requestInfo: {
        name: null,
        bursting_flag: false,
        email_flag: false,
        email: null,
        sms_flag: false,
        sms: null,
        whatsapp_flag: false,
        whatsapp: null,
        child_req_count: null,
        email_comm_template: null,
        email_comm_template_id: null,
        sms_comm_template: null,
        sms_comm_template_id: null,
        whatsapp_comm_template: null,
        whatsapp_comm_template_id: null
      },
      deliveryType: {
        value: null,
        text: null
      },
      toEmail: null,
      ccEmail: null,
      subject: null,
      body: null,
      defaultValue: {
        value: null,
        text: null
      },
      groupByParam: {
        value: null,
        text: null
      },
      groupByParamList: [],
      form: {
        requestId: 0,
        name: {
          value: null,
          text: null
        },
        template: {
          value: null,
          text: null
        },
        runtimeForJob: {
          selectedJob: {
            value: 'asap',
            text: 'Immediate'
          },
          selectedInterval: {
            value: null,
            text: null
          },
          selected: null,
          selectedSchecule: null,
          selectedRadio: null,
          startDate: null,
          endDate: null,
          repeatRun: null,
          day: null,
          date: null
        },
        selectedFormat: {
          value: null,
          text: null
        }
      },
      parameterValue: null,
      parameterString: null,
      data: [
        {
          display_id: null,
          data_input: null,
          parameter_name: null,
          data_input_id: null
        }
      ],
      fields: [
        {
          key: 'display_id',
          label: 'Display Name'
        },
        {
          key: 'parameter_id',
          class: 'd-none'
        },
        {
          key: 'parameter_name'
        },
        {
          key: 'template_id',
          class: 'd-none'
        },

        {
          key: 'parameter_type',
          label: 'Type'
          // class: 'd-none'
        },
        {
          key: 'parameter_show',
          class: 'd-none'
        },

        {
          key: 'vset_id',
          class: 'd-none'
        },
        {
          key: 'vset_name',
          class: 'd-none'
        },

        {
          key: 'width',
          label: 'Width',
          class: 'd-none'
        },
        {
          key: 'mandatory',
          class: 'd-none'
        },
        {
          key: 'multi_flag',
          class: 'd-none'
        },
        {
          key: 'display_flag',
          class: 'd-none'
        },
        {
          key: 'group_by_flag',
          class: 'd-none'
        },
        {
          key: 'default_value',
          class: 'd-none'
        },
        {
          key: 'in_out',
          label: 'IN/OUT',
          class: 'd-none'
        },
        {
          key: 'data_input_id',
          class: 'd-none'
        },
        {
          key: 'data_input',
          label: 'Value',
          class: 'text-center'
        }
      ],
      valueSetData: {
        valueSetName: null,
        dependeParams: null
      },
      showValueSetModal: false,
      type: null,
      active: false,
      unsubscribe: null,
      retryFlag: false,
      retryData: {}
    };
  },
  validations: {
    form: {
      name: {
        value: { required }
      },
      template: {
        value: { required }
      }
    }
  },
  mounted() {
    if (this.reRunReq === 'runReqPrompt') {
      this.retryFlag = true;
      this.retryData = this.retryDtls;
    }
    if (
      this.$route.name == 'gst_r1_summary' ||
      this.$route.name == 'getNearMatchForm'
    ) {
      this.editMode = true;
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'shared/setActionName') {
          const actionName = state.shared.actionName;
          if (actionName === 'save') {
            if (this.data.length > 0) {
              this.joinParameterString();
            }
            this.saveSubmitRequest();
          }
        }
      });
    }
    if (this.retryFlag) {
      this.editMode = true;
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'shared/setActionName') {
          const actionName = state.shared.actionName;
          if (actionName === 'save') {
            if (this.data.length > 0) {
              this.joinParameterString();
            }
            this.saveSubmitRequest();
          }
        }
      });
    }
    setTimeout(() => {
      this.getRequestByUserList();
    }, 0);
    this.getRequestFormat();
    this.eventBus.$on('createFormActions', actionName => {
      if (actionName === 'add') {
        this.resetForm();
        this.editMode = true;
        this.showAlert = false;
      }
      if (actionName === 'save' && this.editMode && !this.retryFlag) {
        if (this.data.length > 0) {
          this.joinParameterString();
        }
        this.saveSubmitRequest();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', { id: this.form.requestId });
      }
    });
  },
  methods: {
    checkName() {
      if (this.form.name.text) {
        this.getDigiSignVerify();
      } else {
        alert('Please select Request Name first');
        this.active = false;
      }
    },
    getDigiSignVerify() {
      const payload = {
        requestHdrId: this.form.name.value
      };
      this.loader = true;
      this.$store
        .dispatch('template/getDigiSignVerify', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (result) {
              this.active = true;
            } else {
              alert(
                'Digital Signature configuration missing for this request.'
              );
              this.active = false;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRequestByUserList() {
      const payload = {
        user_id: store.state.auth.userId,
        // responsibility_id: store.state.shared.responsibilityId,
        responsibility_id:
          this.reRunReq === 'runReqPrompt'
            ? this.retryData.responsiblity_hdr_id
            : store.state.shared.responsibilityId,
        request_name: this.name
      };
      this.$store
        .dispatch('requestMaster/getRequestByUser', payload)
        .then(response => {
          if (response.status === 200) {
            const list = response.data.data;
            const getValueAndText = list.map(obj => {
              return {
                value: obj.request_id,
                text: obj.request_name
              };
            });
            const requestDtlList = list.map(obj => {
              return {
                name: obj.request_name,
                bursting_flag: obj.bursting_flag,
                email_flag: obj.email_flag,
                email: obj.email,
                sms_flag: obj.sms_flag,
                sms: obj.sms,
                whatsapp_flag: obj.whatsapp_flag,
                whatsapp: obj.whatsapp,
                child_req_count: obj.child_req_count,
                email_comm_template: obj.email_comm_template,
                email_comm_template_id: obj.email_comm_template_id,
                sms_comm_template: obj.sms_comm_template,
                sms_comm_template_id: obj.sms_comm_template_id,
                whatsapp_comm_template: obj.whatsapp_comm_template,
                whatsapp_comm_template_id: obj.whatsapp_comm_template_id
              };
            });
            this.requestArr = getValueAndText;
            if (this.$route.name == 'gst_r1_summary') {
              const index = this.requestArr.findIndex(
                obj => obj.text === 'GSTR1 Filing Return Save Request'
              );
              this.form.name = {
                value: this.requestArr[index].value,
                text: this.requestArr[index].text
              };
            }
            if (this.$route.name == 'getNearMatchForm') {
              const index = this.requestArr.findIndex(
                obj => obj.text === 'GST Reconciliation'
              );
              this.form.name = {
                value: this.requestArr[index].value,
                text: this.requestArr[index].text
              };
            }
            if (this.reRunReq === 'runReqPrompt') {
              const index = this.requestArr.findIndex(
                obj => obj.text === this.retryData.name
              );
              this.form.name = {
                value: this.requestArr[index].value,
                text: this.requestArr[index].text
              };
            }
            this.requestInfoList = requestDtlList;
            const paramId = this.$route.params.request_id;
            if (paramId) {
              const findRequest = getValueAndText.find(
                request => request.value === parseInt(paramId)
              );
              if (findRequest) {
                this.paramRequestId = paramId;
                this.editMode = true;
                this.showAlert = false;
                this.form.name = {
                  value: findRequest.value,
                  text: findRequest.text
                };
                this.getRequestBasedFlags(this.form.name.text);
              }
            }
          }
        });
    },
    autoFillDataGstr() {
      if (
        this.$route.name == 'gst_r1_summary' &&
        this.form.name.text == 'GSTR1 Filing Return Save Request'
      ) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].display_id == 'Legal Entity') {
            this.data[i].data_input = this.reqRun.legalEntity.text;
            this.data[i].data_input_id = this.reqRun.legalEntity.value;
          } else if (this.data[i].display_id == 'GST Number') {
            this.data[i].data_input = this.reqRun.gstNumber.text;
            this.data[i].data_input_id = this.reqRun.gstNumber.value;
          } else if (this.data[i].display_id == 'Return Period') {
            this.data[i].data_input = this.reqRun.month.text;
            this.data[i].data_input_id = this.reqRun.month.value;
          }
        }
      } else if (
        this.$route.name == 'getNearMatchForm' &&
        this.form.name.text == 'GST Reconciliation'
      ) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].display_id == 'Legal Entity') {
            this.data[i].data_input = this.reqRunNearMatch.legalEntity.text;
            this.data[i].data_input_id = this.reqRunNearMatch.legalEntity.value;
          } else if (this.data[i].display_id == 'ITC Type') {
            this.data[i].data_input = this.reqRunNearMatch.itcType.text;
            this.data[i].data_input_id = this.reqRunNearMatch.itcType.value;
          } else if (this.data[i].display_id == 'Period from ') {
            this.data[i].data_input = this.reqRunNearMatch.periodFrom.text;
            this.data[i].data_input_id = this.reqRunNearMatch.periodFrom.value;
          } else if (this.data[i].display_id == 'Period To') {
            this.data[i].data_input = this.reqRunNearMatch.periodTo.text;
            this.data[i].data_input_id = this.reqRunNearMatch.periodTo.value;
          }
        }
      }
    },
    getRequestBasedFlags(requestName) {
      const selectedRequest = this.requestInfoList.find(
        key => requestName === key.name
      );
      this.requestInfo = selectedRequest;
    },
    onSelectParamType(event) {
      this.groupByParam.text = event.text;
      this.groupByParam.value = event.value;
    },
    getTemplate() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        // request_name: this.name,
        request_id: this.form.name.value,
        template_name: this.template
      };
      this.$store
        .dispatch('template/getTemplateList', payload)
        .then(response => {
          const results = response.data.data.page;
          this.form.template.value = results[0].template_id;
          this.form.template.text = results[0].template_name;
        });
    },
    getTemplateList(templateId) {
      this.loader = true;
      this.$store
        .dispatch('template/getTemplateByIdList', templateId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.form.selectedFormat = {
              value: result.output_format,
              text: result.request_format
            };
            if (this.form.selectedFormat.value == null) {
              this.getRequestFormat();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest() {
      const mandatoryParameterCheck = this.data.filter(parameter => {
        if (
          parameter.mandatory &&
          (parameter.data_input === null || parameter.data_input === '')
        ) {
          return parameter;
        }
      });
      if (mandatoryParameterCheck.length > 0) {
        this.isSuccess = false;
        this.showAlert = true;
        this.responseMsg = 'Please fill all mandatory values';
        return false;
      } else {
        if (this.validateGroupBy()) {
          this.$v.$touch();
          if (!this.$v.$invalid) {
            for (let i = this.data.length; i < 25; i++) {
              this.data.push({ data_input: null });
            }
            const payload = {
              admin_submit_req_details: [
                {
                  request_num: this.form.requestId,
                  request_id: this.form.name.value,
                  template_id: this.form.template.value,
                  schedule_type: this.form.runtimeForJob.selectedJob.value,
                  request_start_date: this.form.runtimeForJob.startDate
                    ? format(
                        new Date(this.form.runtimeForJob.startDate),
                        'dd-MMM-yyyy'
                      )
                    : null,
                  request_end_date: this.form.runtimeForJob.endDate
                    ? format(
                        new Date(this.form.runtimeForJob.endDate),
                        'dd-MMM-yyyy'
                      )
                    : null,
                  resubmit_interval: this.form.runtimeForJob.repeatRun,
                  resubmit_interval_unit: this.form.runtimeForJob
                    .selectedInterval.value,
                  day_of_month: this.form.runtimeForJob.date,
                  day_of_week: this.form.runtimeForJob.day,
                  output_format_id: this.form.selectedFormat.value,
                  no_of_args: this.data.length,
                  request_parameter: this.parameterValue,
                  sms_flag: this.requestInfo.sms_flag,
                  email_flag: this.requestInfo.email_flag,
                  whatsapp_flag: this.requestInfo.whatsapp_flag,
                  child_req_count: this.requestInfo.child_req_count,
                  email_comm_template_id: this.requestInfo.email_flag
                    ? this.requestInfo.email_comm_template_id
                    : null,
                  sms_comm_template_id: this.requestInfo.sms_flag
                    ? this.requestInfo.sms_comm_template_id
                    : null,
                  whatsapp_comm_template_id: this.requestInfo.whatsapp_flag
                    ? this.requestInfo.whatsapp_comm_template_id
                    : null,
                  delivery_type_vset: this.deliveryType.value,
                  to_email: this.toEmail,
                  cc_email: this.ccEmail,
                  subject: this.subject,
                  body: this.body,
                  digital_sig_req: this.active
                }
              ]
            };
            for (let j = 0; j < 25; j++) {
              if (this.data[j].group_by_flag) {
                this.data[j].data_input =
                  this.data[j].display_id === this.groupByParam.text
                    ? 'Y'
                    : null;
                payload.admin_submit_req_details[0][
                  'argument' + (j + 1)
                ] = this.data[j].data_input;
                continue;
              }
              payload.admin_submit_req_details[0]['argument' + (j + 1)] =
                this.data[j].parameter_type === 'VALUESET'
                  ? this.data[j].data_input_id
                  : this.data[j].data_input;
            }
            this.loader = true;
            this.$store
              .dispatch('template/saveSubmitRequest', payload)
              .then(response => {
                for (let i = this.data.length; i > this.argumentLength; i--) {
                  this.data.pop();
                }
                this.loader = false;
                this.$emit('updateRequestList');
                if (this.retryData.withPara === 'Yes') {
                  this.$emit('hideRunReqModal');
                }
                this.showAlert = true;
                if (response.status === 201) {
                  this.editMode = false;
                  this.isSuccess = true;
                  this.responseMsg = response.data.message;
                } else {
                  this.isSuccess = false;
                  this.responseMsg = response.response.data.message;
                }
              })
              .catch(() => {
                this.loader = false;
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              });
          }
        }
      }
    },
    joinParameterString() {
      this.parameterValue =
        this.data[0].parameter_type === 'VALUESET'
          ? this.data[0].data_input_id
          : this.data[0].data_input;
      for (let i = 1; i < this.data.length; i++) {
        this.parameterString =
          this.data[i].parameter_type === 'VALUESET'
            ? this.data[i].data_input_id
            : this.data[i].data_input;
        this.parameterString = ', ' + this.parameterString;
        this.parameterValue += this.parameterString;
      }
    },
    changeDateFormat(index) {
      this.respTableIndex = index;
    },
    inputDate(dateValue) {
      this.data[this.respTableIndex].data_input = this.data[this.respTableIndex]
        .data_input
        ? format(new Date(dateValue), 'dd-MMM-yyyy')
        : null;
      if (this.primaryDate == null) {
        this.primaryDate = this.data[this.respTableIndex].data_input;
        this.primaryDateId = this.data[this.respTableIndex].display_id;
      } else {
        this.secondaryDate = this.data[this.respTableIndex].data_input;
        this.secondaryDateId = this.data[this.respTableIndex].display_id;
      }
    },
    getRequestFormat() {
      this.$store
        .dispatch('template/getLOVBySetType', 'REQUEST_FORMAT')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.requestFormatArr = getValueAndText;
            this.form.selectedFormat = getValueAndText[2];
          }
        });
    },
    validateGroupBy() {
      if (this.requestInfo.bursting_flag) {
        if (this.groupByParam.text) {
          this.isGroupByValid = true;
          return this.isGroupByValid;
        }
        this.isGroupByValid = false;
        return this.isGroupByValid;
      }
      this.isGroupByValid = true;
      return this.isGroupByValid;
    },
    openRuntimeList() {
      if (this.editMode) {
        this.showRuntimeModal = true;
        this.setTimeRunStatus = setTimeout(() => {
          this.eventBus.$emit(
            'runtimeStatus',
            this.form.runtimeForJob.selectedJob.value
          );
        }, 0);
      }
    },
    schedule(tempData) {
      this.form.runtimeForJob.selectedJob = tempData.selectedJob;
      this.form.runtimeForJob.selectedInterval.value =
        tempData.selectedTimeDuration.value;
      this.form.runtimeForJob.selected = tempData.selected;
      this.form.runtimeForJob.selectedRadio = tempData.selectedRadio;
      this.form.runtimeForJob.startDate = tempData.startDate;
      this.form.runtimeForJob.endDate = tempData.endDate;
      this.form.runtimeForJob.repeatRun = tempData.repeatRun;
      this.form.runtimeForJob.date = tempData.selectedDate;
      this.form.runtimeForJob.day = tempData.selectedDay;
      this.showRuntimeModal = false;
    },
    hideRuntimeModal() {
      this.showRuntimeModal = false;
    },
    openTemplateForm() {
      if (this.form.name.text !== null) {
        this.setTimeDependentTemp = setTimeout(() => {
          this.eventBus.$emit('dependentTemplate', this.form.name.text);
        }, 0);
        this.showTemplateForm = true;
      }
    },
    selectedtemplate(templateObj) {
      this.form.template.text = templateObj.template_name;
      this.form.template.value = templateObj.template_id;
      this.showTemplateForm = false;
    },
    getParameterMapping(templateId) {
      this.$store
        .dispatch('template/getRequestParameterList', templateId)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const details = result.map(items => {
              return {
                display_id: items.display_id,
                parameter_id: items.parameter_id,
                template_id: items.template_id,
                parameter_name: items.parameter_name,
                parameter_type: items.parameter_type,
                parameter_show: items.parameter_show,
                vset_id: items.vset_id,
                vset_name: items.vset_name,
                width: items.width,
                mandatory: items.mandatory,
                multi_flag: items.multi_flag,
                display_flag: items.display_flag,
                group_by_flag: items.group_by_flag,
                default_value: items.default_value,
                in_out: items.in_out,
                data_input_id:
                  items.default_value !== null ? items.default_value : null,
                data_input:
                  items.default_value !== null ? items.default_value : null
              };
            });
            this.data = details;
            if (
              this.$route.name == 'gst_r1_summary' ||
              this.$route.name == 'getNearMatchForm'
            ) {
              this.autoFillDataGstr();
            }
            if (this.retryFlag && this.retryData.withPara === 'Yes') {
              setTimeout(() => {
                this.autoFillRetryData();
              }, 0);
            }
            this.argumentLength = this.data.length;
            const groupParams = details
              .filter(key => key.group_by_flag)
              .map(key => {
                return {
                  value: 'Y',
                  text: key.display_id
                };
              });
            this.groupByParamList = groupParams;
            if (groupParams && groupParams.length > 0) {
              this.groupByParam = {
                value: groupParams[0].value,
                text: groupParams[0].text
              };
            }
          }
        });
    },
    autoFillRetryData() {
      let inputPara = this.retryData.parameter;
      let result = inputPara.split(/,\s*|\s*,/);
      if (result.length === this.data.length) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].display_id) {
            if (result[i] !== 'null') {
              this.data[i].data_input = result[i];
              this.data[i].data_input_id = result[i];
            }
          }
        }
      } else {
        this.data = this.data.filter(ele => ele.display_flag);
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].display_id) {
            this.data[i].data_input = result[i];
            this.data[i].data_input_id = result[i];
          }
        }
      }
      if (this.data.length > 0) {
        this.joinParameterString();
      }
      this.saveSubmitRequest();
    },
    widthType(index) {
      this.respTableIndex = index;
      this.mandatoryParamObj = {};
      if (this.data[this.respTableIndex].parameter_type === 'VALUESET') {
        this.getValuesetDetails(this.data[index].vset_name, index);
      } else {
        this.maxlength = this.data[this.respTableIndex].width;
      }
    },
    // checkParamForVsetFun(index){
    //   if(this.data[index].vset_name !== appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST){
    //     for( index in this.data){
    //      const mandatoryIdName =  index.parameter_name
    //      for ( index in this.mandatoryParam) {
    //        if(index === mandatoryIdName){
    //          alert('')
    //        }
    //      }
    //     }
    //    }
    // },
    openValueSet(index, dependeParams) {
      this.respTableIndex = index;
      this.showValuesetForm = true;
      this.valueSetData = {
        valueSetName: this.data[index].vset_name,
        dependeParams: dependeParams
      };
      // this.setTimeVsetCode = setTimeout(() => {
      //   const vSetData = {
      //     valueSetName: this.data[index].vset_name,
      //     dependeParams: dependeParams
      //   };
      //   this.eventBus.$emit('valueSetCode', vSetData);
      // }, 0);
    },
    selectedDepvalueSet(vsetName) {
      if (this.data[this.respTableIndex].multi_flag) {
        vsetName.forEach(obj => {
          this.valuesetVariable.push(obj.value_code);
          if (this.valueSetTypeLookup === 'TABLE') {
            this.valuesetVariableId.push(obj.value_set_dtl_id);
          } else {
            this.valuesetVariableId.push(obj.value_code);
          }
          this.data[
            this.respTableIndex
          ].data_input = this.valuesetVariable.join(', ');
          this.data[
            this.respTableIndex
          ].data_input_id = this.valuesetVariableId.join(',');
        });
      } else {
        if (this.commTempName) {
          if (this.commTempName === 'email') {
            this.requestInfo.email_comm_template_id = vsetName.value_set_dtl_id;
            this.requestInfo.email_comm_template = vsetName.value_code;
          } else if (this.commTempName === 'sms') {
            this.requestInfo.sms_comm_template_id = vsetName.value_set_dtl_id;
            this.requestInfo.sms_comm_template = vsetName.value_code;
          } else if (this.commTempName === 'whatsapp') {
            this.requestInfo.whatsapp_comm_template_id =
              vsetName.value_set_dtl_id;
            this.requestInfo.whatsapp_comm_template = vsetName.value_code;
          }
        } else {
          this.data[this.respTableIndex].data_input = vsetName.value_code;
          if (this.valueSetTypeLookup === 'TABLE') {
            this.data[this.respTableIndex].data_input_id =
              vsetName.value_set_dtl_id;
          } else {
            this.data[this.respTableIndex].data_input_id = vsetName.value_code;
          }
          this.dependentId = vsetName.value_set_dtl_id;
        }
      }
      this.commTempName = null;
      this.valuesetVariableId = [];
      this.valuesetVariable = [];
      this.showValuesetForm = false;
    },
    getValuesetDetails(vsetCode, index) {
      this.loader = true;
      this.$store
        .dispatch('template/getValuesetDetails', vsetCode)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            // if (response.data.data.value_set_type_lookup === 'TABLE') {
            //   this.mandatoryParam = response.data.data.mandatory_param;
            //   this.optionalParam = response.data.data.optional_param;
            // } else if (response.data.data.value_set_type_lookup === 'DEP') {
            //   this.parentCodeId = response.data.data.parent_value_set_name;
            // }
            this.valueSetTypeLookup = response.data.data.value_set_type_lookup;
            const dependeParams = {};
            if (
              ((response.data.data.value_set_type_lookup === 'TABLE' ||
                response.data.data.value_set_type_lookup === 'DEP') &&
                response.data.data.hasOwnProperty('mandatory_param') &&
                response.data.data.mandatory_param.length > 0) ||
              (response.data.data.hasOwnProperty('optional_param') &&
                response.data.data.optional_param.length > 0)
            ) {
              response.data.data.mandatory_param.forEach(data => {
                const findDepParam = this.data.find(
                  obj => obj.parameter_name.toLowerCase() === data
                );
                if (findDepParam) {
                  dependeParams[findDepParam.parameter_name.toLowerCase()] =
                    findDepParam.data_input_id;
                }
                if (data === 'user_id') {
                  dependeParams[data] = store.state.auth.userId;
                } else if (data === 'res_id') {
                  // dependeParams[data] = store.state.shared.responsibilityId;
                  dependeParams[data] =
                    this.reRunReq === 'runReqPrompt'
                      ? this.retryData.responsiblity_hdr_id
                      : store.state.shared.responsibilityId;
                }
              });
              response.data.data.optional_param.forEach(data => {
                const findDepParam = this.data.find(
                  obj => obj.parameter_name.toLowerCase() === data
                );
                if (findDepParam) {
                  dependeParams[findDepParam.parameter_name.toLowerCase()] =
                    findDepParam.data_input_id;
                }
              });
            }
            this.openValueSet(index, dependeParams);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openCommTemplate(vsetName, commTempName) {
      this.showValuesetForm = true;
      this.commTempName = commTempName;
      this.valueSetData = {
        valueSetName: vsetName,
        dependeParams: null
      };
    },
    resetForm() {
      this.form.name = {
        value: null,
        text: null
      };
      this.form.template = {
        value: null,
        text: null
      };
      this.parameterValue = null;
      this.parameterString = null;
      this.runtimeForJob = {
        selectedInterval: {
          value: null,
          text: null
        }
      };
      this.runtimeForJob = {
        selectedJob: {
          value: 'asap',
          text: 'Immediate'
        }
      };
      this.runtimeForJob.selected = null;
      this.runtimeForJob.selectedSchecule = null;
      this.runtimeForJob.selectedRadio = null;
      this.runtimeForJob.startDate = null;
      this.runtimeForJob.endDate = null;
      this.runtimeForJob.repeatRun = null;
      this.runtimeForJob.day = null;
      this.runtimeForJob.date = null;
    },
    resetModal() {},
    closeValueSetModal() {
      this.showValuesetForm = false;
      this.showValueSetModal = false;
    },
    filterTable(row) {
      if (!row.display_flag) {
        return false;
      } else {
        return true;
      }
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.DELIVERY_TYPE:
          this.deliveryType = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
      }
    },
    openValueSetModal(vsetCode, type) {
      this.type = type;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case this.deliveryType.value:
          this.deliveryType = this.defaultValue;
          break;
      }
    }
  },
  destroyed() {
    this.eventBus.$off('createFormActions');
    clearTimeout(
      this.setTimeRunStatus,
      this.setTimeDependentTemp,
      this.setTimeVsetCode
    );
  },
  beforeDestroy() {
    if (
      this.$route.name == 'gst_r1_summary' ||
      this.$route.name == 'getNearMatchForm' ||
      this.retryFlag
    ) {
      this.unsubscribe();
      this.retryFlag = false;
    }
  }
};
